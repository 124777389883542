import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import Chat from "../components/chat.js";

function Inbox() {
  const context = useContext(AppContext);

  const [chatID, setChatID] = useState(1);
  
  function changeChat(newChatID) {
    if (chatID === newChatID) {
      return;
    }
    setChatID(newChatID);
  }

  return (
    <div style={{height: '100%'}}>

    
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-xs-3 ">
            <div className="card">
              <div className="card-content">
                <div className="side-item" onClick={() => changeChat(1)}>
                  <span>Project chat</span>
                </div>
                <div className="side-item" onClick={() => changeChat(2)}>
                  <span>Patient chat</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-9 h-100">
            <div className="card h-100">
              <div className="card-header">
                <span>Chat header</span>
              </div>
              <div className="card-content" style={{height: 'calc(100% - 80px)'}}>
                <Chat
                  chatID={chatID}
                  autoscroll={true}
                  createMessageCallback={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inbox;
