import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import { Copy, FilePlus, TimeClock, Search, Activity, Minus, CheckLarge, ChevronDown2, Minus2, ChevronUp, ChevronUp2, ChevronDown, UserPlus } from "../components/icons.js";
import { Status, Button, Counter, Input, Avatar } from '../components/component.js';
import Loading from '../components/loading.js';
import Drop from '../components/drop.js';
import Helmet from "../components/helmet.js";
import Editor from "../components/editor.js";
import UserPicker from "../components/userPicker.js";
import LocationPicker from "../components/locationPicker.js";
import { DatePicker, SlotPicker } from "../components/datePicker.js";

function PatientCreate() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [mrn, setMrn] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [caregivers, setCaregivers] = useState([]);


  const [valid, setValid] = useState(false);
  const [validationMessages, setValidationMessages] = useState([]);

  useEffect(() => {
    let valid = true;
    let messages = [];

    // if (!type) {
    //   valid = false;
    //   messages.push('Project type is required');
    // }

    // if (!location && location < 1) {
    //   valid = false;
    //   messages.push('Location is required');
    // }

    // if (!patient) {
    //   valid = false;
    //   messages.push('Patient is required');
    // }

    setValid(valid);
    setValidationMessages(messages);
  }, []);

  function createPatient() {
    let u = context.addSpiner();
    axios.post('/api/admin/iam/users/create', {
      firstName: 'New',
      lastName: 'Patient',

      mrn: '123456789',
      dateOfBirth: 788137200,
      caregivers: [
        { name: 'Caregiver 1', phone: '1234567890' },
      ],
    }).then((response) => {
      navigate('/patient/'+response.data.id);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  if (loading) {
    return <></>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-10 col-xs-offset-1">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-top">
                <div>
                  <span className="typo-header-3xLarge">New patient</span><br/>
                  <span className="typo-normal-175">Fill all the required fields to create a new patient</span>
                </div>
                <div>
                  {valid ? (
                    <Button onClick={createPatient} color="blue" text="Create" />
                  ) : (
                    <Drop>
                      <Button onClick={createPatient} color="blue" text="Create" />
                      <div className="card" style={{width: 200}}>
                        <div className="card-content">
                          {validationMessages.map((m) => {
                            return <div className="typo-normal-150"> - {m}</div>
                          })}
                        </div>
                      </div>
                    </Drop>
                  )}
                </div>
              </div>
            </div>
            <div className="card-header" style={{minHeight: '500px'}}>
              <div className="row mb-3">
                <div className="col-xs-4">
                  <div className="typo-strong-175">First name</div>
                  <input type="text" placeholder="Title" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="input borderless typo-normal-175 w-100" />
                </div>
                <div className="col-xs-4">
                  <div className="typo-strong-175">Last name</div>
                  <input type="text" placeholder="Title" value={lastName} onChange={(e) => setLastName(e.target.value)} className="input borderless typo-normal-175 w-100" />
                </div>
                <div className="col-xs-4">
                  <div className="typo-strong-175">Date of birth</div>
                  <DatePicker 
                    date={new Date()}
                    onChange={() => {}}
                    onChangeID={null}
                    leftText="Date of birth:"
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  />
                </div>
              </div>

                 
                




              {/* <div className="row">
                <div className="col-xs-7">
                  <div className="typo-strong-175">Project type</div>
                    
                  {types.map((t) => {
                    return <div key={"type_"+t.id} className={"item px-3 py-2 mb-2 me-3 " + (type === t.id ? "grey" : "")} onClick={() => changeType(t.id)}>
                      <div className="w-100">
                        <div className="typo-header-150">{t.title}</div>
                        <div className="typo-normal-125">{t.description}</div>
                      </div>
                    </div>
                  })}
                    

                  <div className="typo-strong-175 mt-3">Summary</div>
                  <Editor 
                    placeholder="Description..."
                    value={summary}
                    onChange={setSummary}
                    toolbar="full"
                    className="text-editor"
                  />
                </div>
                <div className="col-xs-4 col-xs-offset-1">
                  <div className="typo-strong-175">Assignee</div>
                  <UserPicker
                    userList={users.filter(user => user.roleID === 2)}
                    userID={user ? user.id : null}
                    onChange={changeUser}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                  />

                  <div className="typo-strong-175 mt-3">Patient</div>
                  <UserPicker
                    userList={users.filter(user => user.roleID === 3)}
                    userID={patient ? patient.id : null}
                    onChange={changePatient}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                  />

                  <div className="typo-strong-175 mt-3">Location</div>
                  <LocationPicker
                    locationList={locations}
                    locationID={location ? location.id : null}
                    onChange={changeLocation}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                  />

                  <div className="typo-strong-175 mt-3">Priority</div>
                  <div className={"item me-1 " + (priority === -2 ? "grey" : "")} onClick={() => changePriority(-2)}>
                    <ChevronDown2 height={16} width={16} background={'none'} fill={'blue'} />
                  </div>
                  <div className={"item me-1 " + (priority === -1 ? "grey" : "")} onClick={() => changePriority(-1)}>
                    <ChevronDown height={16} width={16} background={'none'} fill={'blue'} />
                  </div>
                  <div className={"item me-1 " + (priority === 0 ? "grey" : "")} onClick={() => changePriority(0)}>
                    <Minus2 height={16} width={16} background={'none'} fill={'orange'} />
                  </div>
                  <div className={"item me-1 " + (priority === 1 ? "grey" : "")} onClick={() => changePriority(1)}>
                    <ChevronUp height={16} width={16} background={'none'} fill={'red'} />
                  </div>
                  <div className={"item me-1 " + (priority === 2 ? "grey" : "")} onClick={() => changePriority(2)}>
                    <ChevronUp2 height={16} width={16} background={'none'} fill={'red'} />
                  </div>

                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientCreate;
