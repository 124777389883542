import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, ThumbUp, Heart, Smile, SmileSad, Paperplane, ArrowRight, Comment } from "../components/icons.js";

import { Avatar, Status } from "../components/component.js";

import { AppContext } from '../context.js';

import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";

function Events(props) {
  const { projectID, taskID, appointmentID, list, refresh } = props;

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (list) {
      setEvents(list);
      setLoading(false);
      return;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/communication/events/list', {
      projectID: projectID ? [projectID] : null,
      taskID: taskID ? [taskID] : null,
      appointmentID: appointmentID ? [appointmentID] : null,
    }).then((response) => {
      setEvents(response.data.events);
      setLoading(false);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, [projectID, taskID, appointmentID, list, refresh]);

  if (loading) {
    return (
      <></>
    );
  }

  return (
    <>
      {events.sort((a, b) => b.id - a.id).map((event) => {
        // var content = JSON.parse(event.content);
        switch (event.type) {
          case 'project_created':
            return <ProjectCreated event={event} />;
          case 'task_created':
            return <TaskCreated event={event} />;
          case 'task_status_updated':
            return <TaskStatusUpdated event={event} />;
          case 'appointment_created':
            return <AppointmentCreated event={event} />;
          case 'message_created':
            return <MessageCreated event={event} />;
          default:
            return null;
        }
      })}
    </>
  )
}

function ProjectCreated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">created a project</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function TaskCreated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">created a task</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function TaskStatusUpdated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">changed task status</span>
          <br/>
          <div className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
          <div className="d-flex justify-content-start align-items-center">
            <Status color={"grey"} muted={true} size={'small'} text={content.fromStatusName} className="me-2" />
            <ArrowRight height={14} width={16} background={'none'} fill={'grey'} />
            <Status color={"grey"} muted={true} size={'small'} text={content.toStatusName} className="ms-2" />
          </div>
        </div>
      </div>
    </div>
  );
}

function AppointmentCreated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Folder height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <span className="typo-strong-150 me-1">{event.createdByName}</span>
          <span className="typo-medium-150">created an appointment</span>
          <br/>
          <span className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
        </div>
      </div>
    </div>
  );
}

function MessageCreated(props) {
  const { event } = props;

  const context = useContext(AppContext);

  var content = JSON.parse(event.content);

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-start align-items-top">
        <div className="" style={{width: 30, paddingTop: 5}}>
          <Comment height={16} width={16} background={'none'} fill={'lightgray'} />
          <div style={{height: 50, width: 8, borderRight: '1px solid lightgray'}}></div>
        </div>
        <div>
          <div><span className="typo-strong-150">{event.createdByName}</span> <span className="typo-medium-150">commented</span></div>
          <div className="typo-medium-125">{new Date().getTime() / 1000 < event.createdAt + 604800 ? DateToFormat(TimestampToDate(event.createdAt), "DF") : DateToFormat(TimestampToDate(event.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(event.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
          <div className="item grey">
            <span className="typo-medium-150">{content.content}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;