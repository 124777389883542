import { useEffect, useState, useContext } from "react";
import { useNavigate, createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import { AppContext } from '../context.js';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import Animation from '../files/cubesAnimation.json';

function Spinner(props) {
  const { spiners } = props;

  const context = useContext(AppContext);

  return (
    <div style={{position: 'fixed', left: 0, bottom: 0, visibility: spiners.length > 0 ? 'visible' : 'hidden', height: 80, width: 80}}>
      <DotLottieReact
        data={Animation}
        loop
        autoplay
      />
    </div>
  );
}

export default Spinner;