import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, UserPlus, Edit, ChevronDown } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";
import Drop from "./drop.js";

import { AppContext } from '../context.js';


function BillingPicker(props) {
  const { codeList, codeID, onChange, onChangeID, leftText, leftIcon, rightIcon, className } = props;

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [codes, setCodes] = useState([]);

  const [passedCodeID, setPassedCodeID] = useState(codeID);
  const [selectedCode, setSelectedCode] = useState(codes.find((c) => c.id === passedCodeID));

  const [closeCodeDrop, setCloseCodeDrop] = useState(0);

  useEffect(() => {
    if (codeList) {
      setCodes(codeList);
      setLoading(false);
      return;
    }

    return;
    
    // axios.post('/api/admin/iam/users/list', {
    //   // id: id,
    //   // userID: userID
    // }).then((response) => {
    //   setUsers(response.data.users);
    //   setLoading(false);
    // }).catch((e) => {
    //   console.log(e);
    // });
  }, []);

  useEffect(() => {
    setPassedCodeID(codeID);
    setSelectedCode(codes.find((c) => c.id === codeID));
  }, [codeID, loading]);

  if (loading) {
    return <></>;
  } 

  return (
    <Drop close={closeCodeDrop}>
      <div className={"item grey " + className}>
        {leftIcon && leftIcon}
        {leftText && <span className="typo-normal-150">{leftText}</span>}
        {/* {selectedUser ? (
          <Avatar url={selectedUser.profileImageName} />
        ) : (
          <UserPlus height={16} width={16} background={'none'} fill={'grey'} />
        )} */}
        <span className="typo-normal-150">{selectedCode ? selectedCode.sum + " | " + selectedCode.title : "not set"}</span>
        {rightIcon && rightIcon}
      </div>
      <div className="card shadowmore" style={{width: 'fit-content'}}>
        <div className="card-header sm">
          {codes.map((code, i) => {
            return (
              <div className="side-item small nowrap" onClick={() => { onChange(code, onChangeID); setCloseCodeDrop(closeCodeDrop + 1) }}>
                <span className="typo-normal-150">{code.sum + " | " + code.title}</span>
            </div>
            );
          })}
        </div>
      </div>
    </Drop>
  );
}

export default BillingPicker;