import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";

import { AppContext } from '../context.js';


function Drop(props) {
  const { children, className, close } = props;

  const context = useContext(AppContext);

  const ref = useRef(null);
  const refd = useRef(null);
  
  const [expand, setExpand] = useState(false);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Close the dropdown when the close prop changes
  useEffect(() => {
    setExpand(false);
  }, [close]);

  // Set the position of the dropdown
  useEffect(() => {
    //if (ref.current && ref.current.children[0]) {
      //const childWidth = ref.current.children[0].offsetWidth;
      const childHeight = ref.current.children[0].offsetHeight;
    //}

    const rect = ref.current.children[0].getBoundingClientRect();

    setPosition({ x: rect.left, y: childHeight });
  } , [ref]);

  // Close the dropdown when the user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((ref.current && !ref.current.contains(event.target)) && (refd.current && !refd.current.contains(event.target))) {
        setExpand(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div 
      ref={ref} 
      style={{position: 'relative', display: 'inline-block', width: 'fit-content'}}
      onClick={(e) => setExpand(!expand)}
      className={className}
    >
      {children[0]}
      
      <div ref={refd} style={{position: 'absolute', display: expand ? 'block' : 'none', top: position.y, left: 0, zIndex: 1000 }} onClick={(e) => e.stopPropagation()}>
        {children[1]}
      </div>
      
    </div>
  );
}

export default Drop;