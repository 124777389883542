import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import { Button } from '../components/component.js';

import Background from '../files/background.png';
import LogoDark from '../files/logo-dark.png';

import HipaaCG from '../files/hipaa-cg.png';
import HipaaCMS from '../files/hipaa-cms.png';
import HipaaCR from '../files/hipaa-cr.png';

function Referral() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-between align-items-center h-100 w-100" style={{backgroundColor: '#11254F'}}>
              
      <div className="d-flex justify-content-center align-items-center w-100">
        <div style={{width: 400}}>
          <img src={LogoDark} className="w-50 mb-4" />

          <div className="typo-strong-300" style={{color: "white"}}>Carecierge referral program</div>

          <div className="typo-medium-150 mb-4" style={{color: "white"}}>Our offer</div>

        </div>
      </div>
      <img src={Background} className="" />
    </div>
  );
}

export default Referral;
