import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, UserPlus, Edit, ChevronDown, ChevronUp, ChevronLeft, ChevronRight, Plus, Minus } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";
import Drop from "./drop.js";

import { AppContext } from '../context.js';

import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";


function ResourcePicker(props) {
  const { task, recurrenceNumber, recurrenceType, onChange, onChangeID, leftText, leftIcon, rightIcon, className } = props;

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const [closeDrop, setCloseDrop] = useState(0);

  const hours = Math.floor(task.timeInProgress / 3600);
  const minutes = Math.floor((task.timeInProgress % 3600) / 60);

  const padZero = (num) => (num < 10 ? '0' + num : num);

  if (loading) {
    return <></>;
  } 

  return (
    <Drop close={closeDrop}>
      <div className={"item grey " + className}>
        {leftIcon && leftIcon}
        <span className="typo-normal-150">Effort</span>
        {rightIcon && rightIcon}
      </div>
      <div className="card shadowmore" style={{width: '250px'}}>
        <div className="card-header sm">
          <div className="typo-normal-150 text-muted">Allocated resources</div>
          <div className="typo-normal-150">Task was in progress for {`${hours}:${padZero(minutes)}`} hours</div>
        </div>
      </div>
    </Drop>
  );
}

export default ResourcePicker;