import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, ThumbUp, Heart, Smile, SmileSad, Paperplane, File } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";

import { AppContext } from '../context.js';

import Drop from "../components/drop.js";

import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";

function Chat(props) {
  const { chatID, autoscroll, createMessageCallback } = props;

  const [loading, setLoading] = useState(true);

  const [messages, setMessages] = useState([]);

  const context = useContext(AppContext);

  const fileUploadRef = useRef(null);

  const [closeReactionDrop, setCloseReactionDrop] = useState(0);

  const [messageText, setMessageText] = useState("");

  const [uploadFiles, setUploadFiles] = useState([]);

  const lastMessageRef = useRef(null);

  useEffect(() => {
    let u = context.addSpiner();
    axios.post('/api/admin/communication/messages/list', {
      chatID: chatID
    }).then((response) => {
      setMessages(response.data.messages);
      setLoading(false);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, [chatID]);

  // Exisiting messages
  function createReaction(message, reaction) {
    let u = context.addSpiner();
    axios.post('/api/admin/communication/reactions/create', {
      id: message.id,
      reaction: reaction,
    }).then((response) => {
      message.reactions.push(response.data);
      setCloseReactionDrop(closeReactionDrop + 1);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // New message
  function triggerFileInput() {
    fileUploadRef.current.click();
  }

  function changeFileInput(e) {
    var uploaded = [];
    for (var i = 0; i < e.target.files.length; i++) {
      uploaded.push(e.target.files[i]);
    }
    setUploadFiles(uploadFiles.concat(uploaded));
    console.log(uploadFiles)
  }

  function keyUpMessageText(e) {
    if (e.key === 'Enter') {
      createMessage();
    }
  }

  function createMessageClick() {
    createMessage();
  }

  function createMessage() {
    if (messageText === "" && uploadFiles.length === 0) {
      return;
    }

    var data = new FormData();
    data.append('fileLength', uploadFiles.length);
    data.append('chatID', chatID)
    data.append('content', messageText)

    for (var i = 0; i < uploadFiles.length; i++) {
      data.append('file'+i, uploadFiles[i]);
    }
    
    let u = context.addSpiner();
    axios.post('/api/admin/communication/messages/create', data).then((response) => {
      setMessages(messages.concat(response.data));
      setMessageText("");
      setUploadFiles([]);
      createMessageCallback();
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // Scroll to bottom
  useEffect(() => {
    if (!autoscroll) {
      return;
    }

    if (!lastMessageRef.current) {
      return;
    }

    if (lastMessageRef.current.children.length === 0) {
      return;
    }

    lastMessageRef.current.lastElementChild.scrollIntoView({block: 'end', behavior: 'smooth'});
  }, [messages]);

  // Render
  function renderAttachments(message) {
    if (message.attachments.length === 0) {
      return;
    }
    return message.attachments.map((a) => <FilePreview file={a.file} />);
  }

  function renderReactions(message) {
    const numberOfThumbUps = message.reactions.filter((reaction) => reaction.thumbUp).length;
    const numberOfHearts = message.reactions.filter((reaction) => reaction.heart).length;
    const numberOfLaughs = message.reactions.filter((reaction) => reaction.laugh).length;
    const numberOfSurprised = message.reactions.filter((reaction) => reaction.surprised).length;

    if (numberOfThumbUps === 0 && numberOfHearts === 0 && numberOfLaughs === 0 && numberOfSurprised === 0) {
      return;
    }

    return (
      <div className="d-flex justify-content-start align-items-center ps-2">
        {numberOfThumbUps > 0 && (
          <div className="d-flex justify-content-start align-items-center typo-medium-150 me-3"> 
            <span className="typo-medium-175">👍</span>
            <span className="typo-medium-150">{numberOfThumbUps}</span>
          </div>
        )}

        {numberOfHearts > 0 && (
          <div className="d-flex justify-content-start align-items-center typo-medium-150 me-3">
            <span className="typo-medium-175">❤️</span>
            <span className="typo-medium-150">{numberOfHearts}</span>
          </div>
        )}

        {numberOfLaughs > 0 && (
          <div className="d-flex justify-content-start align-items-center typo-medium-150 me-3">
            <span className="typo-medium-175">😄</span>
            <span className="typo-medium-150">{numberOfLaughs}</span>
          </div>
        )}

        {numberOfSurprised > 0 && (
          <div className="d-flex justify-content-start align-items-center">
            <span className="typo-medium-175">😯</span>
            <span className="typo-medium-150">{numberOfSurprised}</span>
          </div>
        )}
      </div>
    )
  }

  if (loading) {
    return (
      <></>
    );
  }

  let height = 60;
  if (uploadFiles.length > 0) {
    height += uploadFiles.length * 60;
  }

  return (
    <div style={{height: '100%'}}>
      <input ref={fileUploadRef} type="file" className="d-none" onChange={changeFileInput} accept=".jpg,.jpeg,.png,.tiff,.pdf" multiple={true} />

      <div ref={lastMessageRef} className="scrollable" style={{height: "calc(100% - "+height+"px)", overflowX: 'auto'}}>
        {messages.map((message, i) => {
          return (
            <div className={messages.length !== i + 1 ? "mb-2" : ""}>
              <div className="typo-medium-125 ps-2">
                <span>{message.userName}</span>
                <span className="ms-3">{new Date().getTime() / 1000 < message.createdAt + 604800 ? DateToFormat(TimestampToDate(message.createdAt), "DF") : DateToFormat(TimestampToDate(message.createdAt), context.dateFormat)} {DateToFormat(TimestampToDate(message.createdAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</span>
              </div>

              <Drop close={closeReactionDrop} className="">
                <div className={"item " + (message.userID === context.userID ? "blue" : "grey")}>
                  <div className="typo-medium-150">{message.content ? message.content : 'Shared a file'}</div>
                </div>
                <div className="card shadowmore" style={{width: 168}}>
                  <div className="card-header sm">
                    <div className="item me-2" onClick={() => createReaction(message, "thumbUp")}>
                      <ThumbUp height={16} width={16} background={'none'} fill={'grey'} />
                    </div>
                    <div className="item me-2" onClick={() => createReaction(message, "heart")}>
                      <Heart height={16} width={16} background={'none'} fill={'grey'} />
                    </div>
                    <div className="item me-2" onClick={() => createReaction(message, "laugh")}>
                      <Smile height={16} width={16} background={'none'} fill={'grey'} />
                    </div>
                    <div className="item" onClick={() => createReaction(message, "surprised")}>
                      <SmileSad height={16} width={16} background={'none'} fill={'grey'} />
                    </div>
                  </div>
                </div>
              </Drop>

              {renderAttachments(message)}

              {renderReactions(message)}
              
            </div>
          );
        })}
      </div>

      {messages.length > 0 && (
        <hr className="mt-2 mb-2" style={{borderTop: '#ddd'}} />
      )}

      <div className="d-flex justify-content-start align-items-center">
        <div className="item" onClick={() => triggerFileInput()}>
          <FilePlus height={16} width={16} background={'none'} fill={'grey'} />
        </div>
        <input type="text" className="input borderless typo-normal-150 w-100 ms-2" placeholder="Message..." value={messageText} onChange={(e) => setMessageText(e.target.value)} onKeyUp={(e) => keyUpMessageText(e)} />
        <div className="item" onClick={() => createMessageClick()}>
            <Paperplane height={16} width={16} background={'none'} fill={'grey'} />
            <span className="typo-normal-150">Send</span>
          </div>
      </div>

      {uploadFiles.length > 0 && (
        uploadFiles.map((file) => {
          return UploadFilePreview(file);
        })
      )}
    </div>
  )
}

function UploadFilePreview(file) {
  const fileURL = URL.createObjectURL(file);

  return (
    <div className="d-flex justify-content-start align-items-center mt-2">
      <div className="text-center" style={{width: 30}}></div>
      <div className="d-flex justify-content-start align-items-center item w-100">
        <div className="d-flex justify-content-center align-items-center" style={{height: '40px', width: '60px', border: "1px solid #ddd"}}>
          {fileForPreview(file.name) ? (
            <img src={fileURL} style={{maxHeight: '40px', maxWidth: '100%'}} />
          ) : (
            <Folder height={16} width={16} background={'none'} fill={'grey'} />
          )}
        </div>
        <span className="typo-normal-125 ms-1">{file.name}</span>
      </div>
    </div>
  );
}

function FilePreview(props) {
  const { file } = props;

  const context = useContext(AppContext);

  return (
    <div className="d-flex justify-content-start align-items-center mt-2">
      <div className="text-center" style={{width: 30}}>
        
      </div>
      <div className="d-flex justify-content-start align-items-center item w-100">
        <div className="d-flex justify-content-center align-items-center" style={{height: '40px', width: '60px', border: "1px solid #ddd"}}>
          {fileForPreview(file.uuid + "." + file.extension) ? (
            <img src={"/files/"+file.uuid + "." + file.extension + "?token=token1"} style={{maxHeight: '40px', maxWidth: '100%'}} />
          ) : (
            <Folder height={16} width={16} background={'none'} fill={'grey'} />
          )}
        </div>
        <span className="typo-normal-125 ms-1">{file.name + "." + file.extension}</span>
      </div>
    </div>
  );
}

function fileForPreview(filename) {
  var extentionForPreview = ['jpg', 'jpeg', 'png', 'tiff'];
  if (extentionForPreview.includes(filename.split('.').pop())) {
    return true;
  }
  return false;
}

export default Chat;