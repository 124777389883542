import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate, useParams } from "react-router-dom";

import { AppContext } from '../context.js';
import { Copy, FilePlus, TimeClock, Search, UnfoldMore, MarkerPin, MoreVertical, ChevronUp, File, Comment, ChevronDown, ChevronDown2, ChevronDown3, ChevronUp2, ChevronUp3, Minus2, Hashtag, Filter } from "../components/icons.js";
import { Avatar, Status } from "../components/component.js";
import Loading from "../components/loading.js";
import axios from "axios";

import UserPreview from "../components/userPreview.js";
import LocationPreview from "../components/locationPreview.js";

function Patient() {
  const context = useContext(AppContext);

  let { id } = useParams();

  let patientID = Number(id);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [patient, setPatient] = useState(null);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/patient/profiles/get', {
        id: patientID
      }),
      axios.post('/api/admin/care/projects/list', {
        patientID: [patientID]
      }),
      axios.post('/api/admin/iam/users/list', {
        // TODO
      }),
      axios.post('/api/admin/org/locations/list', {
        // TODO
      }),
    ]).then(axios.spread((patient, projects, users, locations) => {
      setPatient(patient.data);
      setProjects(projects.data.projects);
      setUsers(users.data.users);
      setLocations(locations.data.locations);
      setLoading(false);
    })).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  function renderProjects() {
    return projects.map((project) => {
      return (
        <div onClick={() => navigate(`/project/${project.id}`)} className="card clickable mb-2">
          <div className="card-content">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center typo-strong-300">
                {renderProjectPriority(project)}
                  
                <span className="typo-strong-200 ms-3">P-00000{project.id}</span>

                <span className="typo-strong-200 ms-4">{'No title'}</span>
                  
                <div className="d-flex justify-content-start align-items-center ms-4">
                  <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{project.projectTypeName}</span>
                </div>

              </div>
              <div className="d-flex justify-content-end align-items-center w-25">
                {renderProjectStatus(project)}
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-1">
                
              <div className="d-flex justify-content-start align-items-center me-3">
                <UserPreview
                  userList={users.filter(user => user.roleID === 2)}
                  userID={project.userID}
                  leftText="Assignee:"
                />
              </div>

              <div className="d-flex justify-content-start align-items-center me-3">
                <UserPreview
                  userList={users.filter(user => user.roleID === 3)}
                  userID={project.patientID}
                  leftText="Patient:"
                />
              </div>

              <div className="d-flex justify-content-start align-items-center me-3">
                <LocationPreview
                  locationList={locations}
                  locationID={project.locationID}
                  leftText="Location:"
                />
              </div>
                
            </div>
          </div>
        </div>
      );
    });
  }

  function renderProjectPriority(project) {
    switch (project.priority) {
      case -2:
        return <ChevronDown2 height={20} width={20} background={'none'} fill={'blue'} />;
      case -1:
        return <ChevronDown height={16} width={16} background={'none'} fill={'blue'} />;
      case 0:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
      case 1:
        return <ChevronUp height={16} width={16} background={'none'} fill={'red'} />;
      case 2:
        return <ChevronUp2 height={16} width={16} background={'none'} fill={'red'} />;
      default:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
    }
  }

  function renderProjectStatus(project) {
    var statusColor = "blue";

    if (project.statusIsInitial) {
      statusColor = "grey";
    } 

    if (project.statusIsComplete) {
      statusColor = "green";
    }

    return (
      <Status color={statusColor} muted={true} size={"small"} text={project.statusName} />
    );
  }

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="card-content">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-offset-1 col-xs-4">
          <div className="card">
            <div className="card-content">
              <div className="d-flex justify-content-between align-items-top">
                <div className="d-flex justify-content-start align-items-center typo-strong-400">
                  <Avatar url={"38544e18-c3e4-4f08-8766-81b37329419a.jpg"} alt="logo" style={{height: 50, width: 50}} />
                  
                  <div className="ms-3">
                    <div className="typo-strong-300">Kevin Chen</div>
                    <div className="typo-normal-200">Male 35 </div>

                    {/* <div className="typo-strong-300">MemoryCare | Quality Time, Quality Care</div>
                    <div className="typo-normal-200">Asheville, NC 28803</div> */}

                    {/* <div className="typo-strong-300">PocketRN | Your Nurse for Life</div>
                    <div className="typo-normal-200">Asheville, NC 28803</div> */}
                  </div>
                  
                </div>
                
              </div>              
            </div>
          </div>
        </div>
        <div className="col-xs-6">
          <div className="ps-4">
            <div className="card shadowmore mb-2">
              <div className="card-content sm d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <span className="typo-strong-200">Projects</span>
                  <div className="item typo-normal-150 ms-3 active">All</div>
                  {/* <div className={"item typo-normal-150 ms-2 " + (taskFilter === "internal" ? "active" : "")} onClick={() => {setTaskFilter("internal"); setTaskLimit(3)}}>Internal</div>
                  <div className={"item typo-normal-150 ms-2 " + (taskFilter === "patient" ? "active" : "")} onClick={() => {setTaskFilter("patient"); setTaskLimit(3)}}>Patient</div> */}
                </div>
                
                <div className="item typo-normal-150 ms-2"><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
              </div>
            </div>

            {renderProjects()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Patient;
