import { useEffect, useState, useContext } from "react";
import { AppContext } from '../context.js';

function Avatar(props) {
    const { url, className, style } = props;
    const context = useContext(AppContext);
    return (
        <img className={"avatar " + className} src={'/files/' + url+'?token=token1'} style={style} alt="Avatar" />
    );
}

function Button(props) {
    const { onClick, color, muted, size, text, className, leftIcon, rightIcon, style } = props;
    return (
        <button onClick={onClick} className={"button " + color + " " + (muted ? "muted" : "") + " " + size + " typo-body-medium " + className}>
            {leftIcon}
            {text}
            {rightIcon}
        </button>
    );
}

function Status(props) {
    const { color, muted, size, text, className, leftIcon, rightIcon } = props;
    return (
        <div className={"status " + color + " " + (muted ? "muted" : "") + " " + size + " typo-body-medium " + className}>
            {leftIcon}
            {text}
            {rightIcon}
        </div>
    );
}

function Counter(props) {
    const { color, muted, size, text, className } = props;
    return (
        <div className={"counter " + color + " " + (muted ? "muted" : "") + " " + size + " typo-body-small " + className}>
            {text}
        </div>
    );
}

function Input(props) {
    const { value, placeholder, onChange, size, text, className } = props;
    return (
        <input type="text" value={value} placeholder={placeholder} onChange={onChange} className={"input " + size + " typo-body-medium " + className} />
    );
}

export { Avatar, Button, Status, Counter, Input };