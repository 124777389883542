import { useEffect, useState, useContext } from "react";
import { useNavigate, createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, User, CodeAlt, FilePlus } from "../../components/icons.js";

import { Avatar, Counter } from "../../components/component.js";

import Drop from "../../components/drop.js";

import { AppContext } from '../../context.js';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import Animation from '../../files/cubesAnimation.json';

import Logo from '../../files/logo-rect.png';

function Navigation(props) {
  const { children } = props;

  const context = useContext(AppContext);

  const navigate = useNavigate();

  return (
    <>
        <div style={{paddingTop: '30px', paddingBottom: '30px'}}>
            {children}
        </div>
        
        {/* <div className="card w-100" style={{position: 'fixed', bottom: 0, boxShadow: '0px -2px 2px -1px rgba(27, 36, 44, 0.04), 0px -16px 24px -6px rgba(27, 36, 44, 0.16)'}}>
            <div className="d-flex justify-content-between align-items-center px-4 py-2">
                <div className="item" onClick={() => navigate('/menu')}>
                    <img src={Logo} style={{height: 32}} />
                </div>
                <div className="item" onClick={() => navigate('/projects')}>
                    <Folder height={24} width={24} background={'none'} fill={'grey'} />
                </div>
                <div className="item" onClick={() => navigate('/inbox')}>
                    <Inbox height={24} width={24} background={'none'} fill={'grey'} />
                </div>
                <div className="item" onClick={() => navigate('/profile')}>
                    <User height={24} width={24} background={'none'} fill={'grey'} />
                </div>
            </div>
        </div> */}
    </>
  );
}

export default Navigation;