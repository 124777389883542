import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";

import { AppContext } from '../context.js';

function Loading() {
  const context = useContext(AppContext);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          ...
        </div>
      </div>
    </div>
  );
}

export default Loading;
