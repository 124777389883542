import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import { Copy, FilePlus, TimeClock, Search, Activity, Minus, CheckLarge, ChevronDown2, Minus2, ChevronUp, ChevronUp2, ChevronDown, UserPlus } from "../components/icons.js";
import { Status, Button, Counter, Input, Avatar } from '../components/component.js';
import Loading from '../components/loading.js';
import Drop from '../components/drop.js';
import Helmet from "../components/helmet.js";
import Editor from "../components/editor.js";
import UserPicker from "../components/userPicker.js";
import LocationPicker from "../components/locationPicker.js";

function ProjectCreate() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);

  const [type, setType] = useState(null);
  const [user, setUser] = useState(null);
  const [patient, setPatient] = useState(null);
  const [location, setLocation] = useState(null);
  const [summary, setSummary] = useState(null);
  const [priority, setPriority] = useState(0);
  const [tags, setTags] = useState([]);

  const [valid, setValid] = useState(false);
  const [validationMessages, setValidationMessages] = useState([]);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/care/projects/types/list', {
        // TODO
      }),
      axios.post('/api/admin/iam/users/list', {
        // TODO
      }),
      axios.post('/api/admin/org/locations/list', {
        // TODO
      }),
    ]).then(axios.spread((types, users, locations) => {
      setTypes(types.data.types);
      if (types.data.types.length > 0) {
        setType(types.data.types[0].id);
        setSummary(types.data.types[0].defaultSummary);
      }
      setUsers(users.data.users);
      setLocations(locations.data.locations);
      setLoading(false);
    })).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  useEffect(() => {
    let valid = true;
    let messages = [];

    if (!type) {
      valid = false;
      messages.push('Project type is required');
    }

    // if (!location && location < 1) {
    //   valid = false;
    //   messages.push('Location is required');
    // }

    // if (!patient) {
    //   valid = false;
    //   messages.push('Patient is required');
    // }

    setValid(valid);
    setValidationMessages(messages);
  }, [type]);

  function changeType(id) {
    setType(id);
    setSummary(types.find((t) => t.id === id).defaultSummary);
  }

  function changePriority(id) {
    setPriority(id);
  }

  function changeLocation(id) {
    setLocation(id);
  }

  function changeUser(user, id) {
    setUser(user);
  }

  function changePatient(patient, id) {
    setPatient(patient);
  }

  function changeLocation(location, id) {
    setLocation(location);
  }

  function createProject() {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/create', {
      typeID: type,
      userID: user ? user.id : null,
      patientID: patient ? patient.id : null,
      locationID: location ? location.id : null,
      priority: priority,
      summary: summary,
      tags: tags,
    }).then((project) => {
      navigate('/project/'+project.data.id);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  if (loading) {
    return <></>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-10 col-xs-offset-1">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-top">
                <div>
                  <span className="typo-header-3xLarge">New project</span><br/>
                  <span className="typo-normal-175">Fill all the required fields to create a new project</span>
                </div>
                <div>
                  {valid ? (
                    <Button onClick={createProject} color="blue" text="Create" />
                  ) : (
                    <Drop>
                      <Button onClick={createProject} color="blue" text="Create" />
                      <div className="card" style={{width: 200}}>
                        <div className="card-content">
                          {validationMessages.map((m) => {
                            return <div className="typo-normal-150"> - {m}</div>
                          })}
                        </div>
                      </div>
                    </Drop>
                  )}
                </div>
              </div>
            </div>
            <div className="card-header" style={{minHeight: '500px'}}>
              <div className="row">
                <div className="col-xs-7">
                  <div className="typo-strong-175">Project type</div>
                  
                  <div className="row">
                    {types.map((t) => {
                      return <div className="col-xs-4 mb-2" key={"type_"+t.id}>
                        <div className={"item w-100 " + (type === t.id ? "grey" : "")} onClick={() => changeType(t.id)}>
                          <div className="w-100">
                            <div className="typo-header-150">{t.title}</div>
                            <div className="typo-normal-125">{t.description}</div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                  

                  <div className="typo-strong-175 mt-3">Summary</div>
                  <Editor 
                    placeholder="Description..."
                    value={summary}
                    onChange={setSummary}
                    toolbar="full"
                    className="text-editor"
                  />

                  <div className="typo-strong-175 mt-3">Tasks</div>

                  <div className="typo-strong-175 mt-3 mb-4">Appointments</div>
                </div>
                <div className="col-xs-4 col-xs-offset-1">
                  <div className="typo-strong-175">Assignee</div>
                  <UserPicker
                    userList={users.filter(user => user.roleID === 2)}
                    userID={user ? user.id : null}
                    onChange={changeUser}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                  />

                  <div className="typo-strong-175 mt-3">Patient</div>
                  <UserPicker
                    userList={users.filter(user => user.roleID === 3)}
                    userID={patient ? patient.id : null}
                    onChange={changePatient}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                  />

                  <div className="typo-strong-175 mt-3">Location</div>
                  <LocationPicker
                    locationList={locations}
                    locationID={location ? location.id : null}
                    onChange={changeLocation}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                  />

                  <div className="typo-strong-175 mt-3">Priority</div>
                  <div className={"item me-1 " + (priority === -2 ? "grey" : "")} onClick={() => changePriority(-2)}>
                    <ChevronDown2 height={16} width={16} background={'none'} fill={'blue'} />
                  </div>
                  <div className={"item me-1 " + (priority === -1 ? "grey" : "")} onClick={() => changePriority(-1)}>
                    <ChevronDown height={16} width={16} background={'none'} fill={'blue'} />
                  </div>
                  <div className={"item me-1 " + (priority === 0 ? "grey" : "")} onClick={() => changePriority(0)}>
                    <Minus2 height={16} width={16} background={'none'} fill={'orange'} />
                  </div>
                  <div className={"item me-1 " + (priority === 1 ? "grey" : "")} onClick={() => changePriority(1)}>
                    <ChevronUp height={16} width={16} background={'none'} fill={'red'} />
                  </div>
                  <div className={"item me-1 " + (priority === 2 ? "grey" : "")} onClick={() => changePriority(2)}>
                    <ChevronUp2 height={16} width={16} background={'none'} fill={'red'} />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCreate;
