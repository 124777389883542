import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';

import { Button } from '../components/component.js';

function Profile() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  function logout() {
    context.clearState()
    navigate("/")
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12">
          <div className="typo-hero-medium mb-3">Profile</div>
          <Button color="red" text="Log Out" onClick={logout} />
        </div>
      </div>
    </div>
  );
}

export default Profile;
