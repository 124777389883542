import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate, Link, useParams } from "react-router-dom";
import axios from 'axios';

import { Avatar, Status, Button } from "../components/component.js";
import { Copy, FilePlus, TimeClock, MarkerPin, MoreVertical, ChevronUp, File, Comment, ChevronDown, ChevronDown2, ChevronDown3, ChevronUp2, ChevronUp3, Minus2, Hashtag, ArrowRight, Close, UserPlus } from "../components/icons.js";
import Editor from "../components/editor.js";
import Drop from "../components/drop.js";
import Chat from "../components/chat.js";
import Events from "../components/events.js";

import Background from '../files/background.png';
import LogoDark from '../files/logo-dark.png';
import Logo from "../files/logo.svg";

import { AppContext } from '../context.js';

import TaskCreateModal from "../desktop/task-create-modal.js";
import AppointmentCreateModal from "../desktop/appointment-create-modal.js";

import { TimestampToDate, DateToFormat } from "../utils/datetime.js";

import UserPicker from "../components/userPicker.js";
import UserPreview from "../components/userPreview.js";
import LocationPicker from "../components/locationPicker.js";
import LocationPreview from "../components/locationPreview.js";

function PatientProject() {
  const context = useContext(AppContext);
  
  const navigate = useNavigate();

  let { id } = useParams();
  let projectID = Number(id);

  const [loading, setLoading] = useState(true);

  const [project, setProject] = useState(null);
  const [users, setUsers] = useState([]);
  const [org, setOrg] = useState(null);

  const [taskLimit, setTaskLimit] = useState(3);

  useEffect(() => {
    // axios.post('/api/patient/care/project/get', {
    //   id: projectID,
    // }).then((response) => {
    //   setProject(response.data);
    //   setLoading(false);
    // }).catch((e) => {
    //   console.log(e);
    // });

    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/care/projects/get', {
        id: 1
      }),
      axios.post('/api/admin/iam/users/list', {
        // TODO
      }),
      axios.post('/api/admin/org/settings/get', {
        // TODO
      }),
    ]).then(axios.spread((project, users, org) => {
      setProject(project.data);
      setUsers(users.data.users);
      setOrg(org.data);
      // setLocations(locations.data.locations);
      setLoading(false);
    })).catch((e) => {
      console.log(e);

      if (e.response.status === 401) {
        context.clearState();
        navigate("/");
      }
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  function renderTasks() {
    return project.tasks.filter((t) => t.patientTask).slice(0, taskLimit).map((task, ti) => {
      return (
        <div key={"task_" + task.id} className="mb-2">
          <div className="card">
            <div className="card-header sm">
              <div className="d-flex justify-content-between align-items-center">
                <input type="text" className="input borderless typo-strong-200 w-100" placeholder="Title..." value={task.title} readOnly={true} />
                <div className="d-flex justify-content-end align-items-center w-25">
                  {renderTaskStatus(task.statusID)}
                </div>
              </div>

              {task.instruction && (
                <Editor 
                  value={task.instruction}
                  readOnly={true}
                  toolbar="full"
                  className="text-editor borderless"
                />
              )}

              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="d-flex justify-content-start align-items-center w-75">
                  <UserPreview
                    userList={users}
                    userID={task.userID}
                    onChange={() => {}}
                    onChangeID={task.id}
                    leftText="Assignee:"
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  function renderTasksLimit() {
    const tasks = project.tasks.filter((t) => t.patientTask);
    if (tasks.length <= taskLimit) {
      return null;
    }

    if (tasks.length > taskLimit) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <div className="item typo-normal-150" onClick={() => setTaskLimit(taskLimit + 3)}>Show more</div>
          <div className="item typo-normal-150 ms-2" onClick={() => setTaskLimit(taskLimit + 100)}>Show all</div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <div className="item typo-normal-150" onClick={() => setTaskLimit(3)}>Show less</div>
        </div>
      );
    }
  }

  function renderAppointments() {
    return project.appointments.map((appointment, ai) => {
       return (
        <div key={"appointment_" + ai} className="mb-2">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-top">

                <div className="d-flex justify-content-between align-items-top" style={{width: 120}}>
                  <div className="pe-3" style={{width: 40}}>
                    <div className="typo-strong-300 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), 'DD')}</div>
                    <div className="typo-strong-150 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), 'M3').toUpperCase()}</div>
                  </div>
                  <div style={{width: 80}}>
                    <div className="typo-strong-175 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
                    <div className="typo-normal-125 text-center text-brand">{appointment.duration} min</div>
                    <div className="typo-strong-175 text-center text-brand">{DateToFormat(TimestampToDate(appointment.endAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
                  </div>
                </div>

                <div className="w-100 ms-3 ps-3" style={{borderLeft: '1px solid lightgrey'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <input type="text" className="input borderless typo-strong-200 w-100" placeholder="Title..." value={appointment.title} readOnly={true} />
                    <div className="d-flex justify-content-end align-items-center w-25">
                      {renderAppointmentStatus(appointment.statusID)}
                    </div>
                  </div>
                  
                  {appointment.instruction && (
                    <Editor 
                      value={appointment.instruction}
                      onBlur={() => {}}
                      readOnly={true}
                      toolbar="full"
                      className="text-editor borderless"
                    />
                  )}
                    
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="d-flex justify-content-start align-items-center w-75">
                      <UserPreview
                        userList={users}
                        userID={appointment.userID}
                        onChange={() => {}}
                        onChangeID={appointment.id}
                        leftText="Assignee:"
                        rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                      />
                      
                      {/* <div className="d-flex justify-content-start align-items-center ms-4">
                        <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{appointment.appointmentTypeName}</span>
                      </div> */}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  function renderTaskStatus(id) {
    const status = project.taskStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  function renderAppointmentStatus(id) {
    const status = project.appointmentStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  if (loading) {
    return (
      <></>
    );
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-offset-2 col-xs-8">
            <div className="row mb-3">
              <div className="col-xs-12">
                <div className="card">
                  <div className="card-content">
                    <div className="d-flex justify-content-between align-items-top">
                      <div className="d-flex justify-content-start align-items-center typo-strong-400">
                        <img src={"/files/"+org.logo+"?token=token1"} alt="logo" style={{height: 50}} />
                        <div className="ms-3">
                        <div className="typo-strong-300">{org.name + (org.slogan ? ' | ' + org.slogan : '')}</div>
                        <div className="typo-normal-200">3485 Windsor Ave. Dubuque, IA 52001</div>
                        </div>
                        
                      </div>
                      <span className="typo-medium-150">Your Care Navigator is Just a Call Away <a href="tel:(828) 771-2219" style={{color: 'blue'}}>(828) 771-2219</a></span>
                      
                    </div>

                    <div className="d-flex justify-content-start align-items-center mt-2">

                      <div className="item grey">
                        <span className="typo-normal-150">Project number:</span>
                        <span className="typo-normal-150">G-0{project.id}</span>
                        {/* {rightIcon && rightIcon} */}
                      </div>

                      <div className="d-flex justify-content-start align-items-center ms-4">
                        {project.userID && (
                          <UserPreview
                            userList={users}
                            userID={project.userID}
                            onChange={() => {}}
                            onChangeID={project.id}
                            leftText="Assignee:"
                            rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                          />
                        )}
                      </div>

                      <div className="item grey ms-4">
                        <span className="typo-normal-150">Start date:</span>
                        <span className="typo-normal-150">11/1/2024</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-5">
                <span className="typo-strong-200">Care Plan Review</span>
                <div className="card mb-3">
                  
                  <div className="card-header">
                    {project.summaryPublic ? (
                      <Editor 
                        placeholder="Description..."
                        value={project.summaryPublic}
                        onBlur={() => {}}
                        readOnly={true}
                        toolbar="full"
                        className="text-editor borderless"
                      />
                    ) : (
                      <span className="typo-medium-150">Not posted yet</span>
                    )}
                  </div>
                  <div className="card-content">
                    <Chat
                      chatID={project.patientChatID}
                      createMessageCallback={() => {}}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xs-7">
                <div className="ps-4">
                  {project.tasks.filter((t) => t.patientTask).length > 0 && (
                    <>
                      <span className="typo-strong-200">Tasks</span>
                      {renderTasks()}
                      {renderTasksLimit()}
                      <div className="mt-4"></div>
                    </>
                  )}
                  
                  {project.appointments.length > 0 && (
                    <>
                      <span className="typo-strong-200">Appointments</span>
                      {renderAppointments()}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientProject;
