import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

import { AppContext } from '../context.js';

function Editor(props) {
  const { placeholder, value, onChange, onChangeID, onBlur, toolbar, readOnly, className, style } = props;

  const context = useContext(AppContext);

  const [text, setText] = useState('');

  useEffect(() => {
    setText(value);
  }, [value]);

  function change(value) {
    setText(value);
    if (onChange) {
      onChange(value, onChangeID);
    }
  }

  function blur(previousRange, source, editor) {
    let html = editor.getHTML();
    if (onBlur) {
      onBlur(html, onChangeID);
    }
  }

  const [toolbarOptions, setToolbarOptions] = useState(
    toolbar === 'full' ? [
      [
        { header: "1" }, 
        { header: "2" }, 
        //{ font: [] }
      ],
      //[{ size: [] }],
      [
        "bold", 
        "italic", 
        //"underline", 
        //"strike", 
        //"blockquote"
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        //{ indent: "-1" },
        //{ indent: "+1" },
      ],
      //["link", "image", "video"],
      //["clean"],
    ] : toolbar === 'limited' ? [
      [
        "bold", 
        "italic", 
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
      ],
    ] : [
      
    ]
  );

  return (
    <ReactQuill 
      theme="bubble"
      placeholder={placeholder}
      modules={{
        toolbar: toolbarOptions,
        clipboard: {
            matchVisual: false
        }
      }}
      value={text}
      onChange={change}
      onBlur={blur}
      readOnly={readOnly}
      className={className}
      style={style}
    />
  );
}

export default Editor;
