import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate, Link } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';
import { Copy, FilePlus, TimeClock, Search, UnfoldMore, MarkerPin, MoreVertical, ChevronUp, File, Comment, ChevronDown, ChevronDown2, ChevronDown3, ChevronUp2, ChevronUp3, Minus2, Hashtag, Filter } from "../components/icons.js";

import { Avatar, Status, Button, Counter } from '../components/component.js';
import Loading from '../components/loading.js';
import Helmet from "../components/helmet.js";

import UserPreview from "../components/userPreview.js";
import LocationPreview from "../components/locationPreview.js";

function Projects() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState([]);
  const [filterSelected, setFilterSelected] = useState(0);
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const [filter, setFilter] = useState({
    projectTypeID: [1, 2, 3, 4, 5, 6, 7],
    userID: [0, 2, 3, 4],
    patientID: [0, 5, 6, 7, 8, 9, 10, 11],
    locationID: [0, 1, 2, 3, 4, 5, 6, 7],
    status: [1, 2, 3, 4, 5],
    priority: [-2, -1, 0, 1, 2],
  });

  const [projects, setProjects] = useState([]);

  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/user/filters/projects/list', {
        // TODO add user id
      }),
      axios.post('/api/admin/care/projects/list', filter),
      axios.post('/api/admin/iam/users/list', {
        // TODO
      }),
      axios.post('/api/admin/org/locations/list', {
        // TODO
      }),
    ]).then(axios.spread((filters, projects, users, locations) => {
      setFilters(filters.data.filters);
      setProjects(projects.data.projects);
      setUsers(users.data.users);
      setLocations(locations.data.locations);
      setLoading(false);
    })).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, [filter]);

  function filterChange(filter) {
    setFilterSelected(filter.id);
    setFilter({
      projectTypeID: filter.projectTypeID,
      userID: filter.assignedUserID,
      patientID: filter.patientID,
      locationID: filter.locationID,
      status: filter.status,
      priority: filter.priority,
    });
    setLoading(true);
  }

  function renderProjects() {
    return projects.map((project) => {
      return (
        <div onClick={() => navigate(`/project/${project.id}`)} className="card clickable mb-2">
          <div className="card-content">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center typo-strong-300">
                
                <span className="typo-strong-200">{project.projectType.projectAlias}-{renderProjectID(project)}</span>

                <span className="typo-strong-200 ms-4">{project.summary ? project.summary : 'No title'}</span>
                  
                <div className="d-flex justify-content-start align-items-center ms-4">
                  <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{project.projectTypeName}</span>
                </div>

              </div>
              <div className="d-flex justify-content-end align-items-center w-25">
                {renderProjectStatus(project)}
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-1">

              <div className="d-flex justify-content-start align-items-center me-3">
                <div className={"item grey " + (project.priority < 0 ? "blue" : "") + (project.priority > 0 ? "red" : "")}>
                  {renderProjectPriority(project)}
                  <span className="typo-normal-150">{project.projectType.projectPriorityLabels[project.priority+2]}</span>
                </div>
              </div>
                
              <div className="d-flex justify-content-start align-items-center me-3">
                <UserPreview
                  userList={users.filter(user => user.roleID === 2)}
                  userID={project.userID}
                  leftText="Assignee:"
                />
              </div>

              <div className="d-flex justify-content-start align-items-center me-3">
                <UserPreview
                  userList={users.filter(user => user.roleID === 3)}
                  userID={project.patientID}
                  leftText="Patient:"
                />
              </div>

              <div className="d-flex justify-content-start align-items-center me-3">
                <LocationPreview
                  locationList={locations}
                  locationID={project.locationID}
                  leftText="Location:"
                />
              </div>
                
            </div>
          </div>
        </div>
      );
    });
  }

  function renderProjectPriority(project) {
    switch (project.priority) {
      case -2:
        return <ChevronDown2 height={20} width={20} background={'none'} fill={'blue'} />;
      case -1:
        return <ChevronDown height={16} width={16} background={'none'} fill={'blue'} />;
      case 0:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
      case 1:
        return <ChevronUp height={16} width={16} background={'none'} fill={'red'} />;
      case 2:
        return <ChevronUp2 height={16} width={16} background={'none'} fill={'red'} />;
      default:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
    }
  }

  function renderProjectStatus(project) {
    var statusColor = "blue";

    if (project.statusIsInitial || project.statusIsPaused) {
      statusColor = "grey";
    } 

    if (project.statusIsCompleted) {
      statusColor = "green";
    }

    if (project.statusIsCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={"small"} text={project.statusName} />
    );
  }

  function renderProjectID(project) {
    return project.id.toString().padStart(project.projectType.projectMask, '0');
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet title="Projects" />

      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="card mb-4">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  {/* <div className="d-flex align-items-center text-muted">
                    <Search height={16} width={16} background={'none'} fill={'grey'} /> <span className="ms-1">Type to search...</span>
                  </div> */}
                  <div className="d-flex align-items-center">
                    <div className="item rect" onClick={() => setFiltersExpanded(!filtersExpanded)}>
                      <Filter height={14} width={16} background={'none'} fill={'grey'} />
                    </div>
                    <div className={"item ms-2 " + (filterSelected === 0 ? "active" : "")} onClick={() => setFilterSelected(0)}>
                      <span className="typo-normal-150">Queue</span>
                    </div>
                    {filters.map((filter) => {
                      return (
                        <div className={"item ms-2 " + (filterSelected === filter.id ? "active" : "")} onClick={() => filterChange(filter)}>
                          <span className="typo-normal-150">{filter.title}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <div className="item typo-normal-150 ms-2" onClick={() => navigate('/project/create')}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                  </div>
                </div>
              </div>
              {filtersExpanded && (
                <div className="card-content">
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    Many types of filters, if expanded, will be shown here.
                    <br/>
                    Priority
                  </div>
                </div>
              )}
            </div>
            {renderProjects()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
