import { useEffect, useState, useRef, useContext } from "react";
import { createBrowserRouter, useNavigate, Link } from "react-router-dom";

import { Status, Button, Counter, Input } from '../components/component.js';
import { AlarmClock } from "../components/icons.js";
import Drop from "../components/drop.js";

import { AppContext } from '../context.js';

function Components() {
  const context = useContext(AppContext);

  const inputRef = useRef(null);
  const dropRef = useRef(null);
  const buttonRef = useRef(null);
  const statusRef = useRef(null);
  const counterRef = useRef(null);
  const sidebarRef = useRef(null);
  const typographyRef = useRef(null);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-3">
          <div className="card sticky-top st-1">
            <div className="card-content">
              <span className="typo-strong-200">Components:</span><br/>
              <button onClick={() => inputRef.current.scrollIntoView({behavior: 'smooth'})}>Input</button><br/>
              <button onClick={() => dropRef.current.scrollIntoView({behavior: 'smooth'})}>Drop</button><br/>
              <button onClick={() => buttonRef.current.scrollIntoView({behavior: 'smooth'})}>Button</button><br/>
              <button onClick={() => statusRef.current.scrollIntoView({behavior: 'smooth'})}>Status</button><br/>
              <button onClick={() => counterRef.current.scrollIntoView({behavior: 'smooth'})}>Counter</button><br/>
              <button onClick={() => sidebarRef.current.scrollIntoView({behavior: 'smooth'})}>Sidebar navigation</button><br/>
              <button onClick={() => typographyRef.current.scrollIntoView({behavior: 'smooth'})}>Typography</button>
            </div>
          </div>
        </div>
        <div className="col-xs-9">
          <div ref={dropRef} className="card mb-4">
            <div className="card-content">
              <div className="typo-strong-200">Input</div>
              <div className="d-flex justify-content-start align-items-center mb-2">
                <Input value="" placeholder="Text..." />
                <Button color="white" size="big" text="Button label" className="ms-2" />
              </div>
              <div className="d-flex justify-content-start align-items-center mb-2">
                <Input value="" placeholder="Text..." />
                <Button color="white" text="Button label" className="ms-2" />
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Input value="" placeholder="Text..." />
                <Button color="white" size="small" text="Button label" className="ms-2" />
              </div>
            </div>
          </div>
          <div ref={dropRef} className="card mb-4">
            <div className="card-content">
              <div className="typo-strong-200">Drop</div>
              {/* <Drop>
                <Button color="white" size="big" text="Mouse controlled" />
                <div className="card" style={{width: 200}}>
                  <div className="card-content">
                    <div className="typo-strong-200">Drop down block</div>
                  </div>
                </div>
              </Drop> */}

              <Drop click={true}>
                <Button color="white" size="big" text="Click controlled" />
                <div className="card" style={{width: 200}}>
                  <div className="card-content">
                    <div className="typo-strong-200">Drop down block 1</div>
                  </div>
                </div>
              </Drop>

              {/* <Drop click={true}>
                <Button color="white" size="big" text="Click controlled" />
                <div className="card" style={{width: 200}}>
                  <div className="card-content">
                    <div className="typo-strong-200">Drop down block 2</div>
                  </div>
                </div>
              </Drop> */}
            </div>
          </div>
          <div ref={buttonRef} className="card mb-4">
            <div className="card-content">
              <div className="typo-strong-200">Button</div>
              <div className="mb-2">
                <Button color="white" size="big" text="Button label" className="me-2" />
                <Button color="grey"  size="big" text="Button label" className="me-2" />
                <Button color="blue"  size="big" text="Button label" className="me-2" />
                <Button color="green" size="big" text="Button label" className="me-2" />
                <Button color="red"   size="big" text="Button label" className="me-2" />

              </div>
              <div className="mb-2">
                <Button color="white" text="Button label" className="me-2" />
                <Button color="grey"  text="Button label" className="me-2" />
                <Button color="blue"  text="Button label" className="me-2" />
                <Button color="green" text="Button label" className="me-2" />
                <Button color="red"   text="Button label" className="me-2" />

              </div>
              <div className="mb-2">
                <Button color="white" size="small" text="Button label" className="me-2" />
                <Button color="grey"  size="small" text="Button label" className="me-2" />
                <Button color="blue"  size="small" text="Button label" className="me-2" />
                <Button color="green" size="small" text="Button label" className="me-2" />
                <Button color="red"   size="small" text="Button label" className="me-2" />
              </div>
            </div>
          </div>
          <div ref={statusRef} className="card mb-4">
            <div className="card-content">
              <div className="typo-strong-200">Status</div>
              <div className="mb-2">
                <Status color="white"   text="status" className="me-2" />
                <Status color="grey"   text="status" className="me-2" />
                <Status color="blue"   text="status" className="me-2" />
                <Status color="green"  text="status" className="me-2" />
                <Status color="red"    text="status" className="me-2" />
                <Status color="orange" text="status" className="me-2" />
              </div>
              <div className="mb-2">
                <Status color="white"   muted={true} text="status" className="me-2" />
                <Status color="grey"   muted={true} text="status" className="me-2" />
                <Status color="blue"   muted={true} text="status" className="me-2" />
                <Status color="green"  muted={true} text="status" className="me-2" />
                <Status color="red"    muted={true} text="status" className="me-2" />
                <Status color="orange" muted={true} text="status" className="me-2" />
              </div>
              <div className="mb-2">
                <Status color="white"   text="status" size="small" className="me-2" />
                <Status color="grey"   text="status" size="small" className="me-2" />
                <Status color="blue"   text="status" size="small" className="me-2" />
                <Status color="green"  text="status" size="small" className="me-2" />
                <Status color="red"    text="status" size="small" className="me-2" />
                <Status color="orange" text="status" size="small" className="me-2" />
              </div>
              <div>
                <Status color="white"   muted={true} text="status" size="small" className="me-2" />
                <Status color="grey"   muted={true} text="status" size="small" className="me-2" />
                <Status color="blue"   muted={true} text="status" size="small" className="me-2" />
                <Status color="green"  muted={true} text="status" size="small" className="me-2" />
                <Status color="red"    muted={true} text="status" size="small" className="me-2" />
                <Status color="orange" muted={true} text="status" size="small" className="me-2" />
              </div>
            </div>
          </div>
          <div ref={counterRef} className="card mb-4">
            <div className="card-content">
              <div className="typo-strong-200">Counter</div>
              <div className="mb-2">
                {/* <Counter color="grey"   text="21" className="me-2" />
                <Counter color="blue"   text="21" className="me-2" />
                <Counter color="green"  text="21" className="me-2" /> */}
                <Counter color="red"    text="21" className="me-2" />
                {/* <Counter color="orange" text="21" className="me-2" /> */}
              </div>
            </div>
          </div>
          <div ref={sidebarRef} className="card mb-4">
            <div className="card-content">
              <div className="typo-strong-200">Sidebar navigation</div>
              <div className="side-item">Menu</div>
              <div className="side-item active">Projects</div>
            </div>
          </div>
          <div ref={typographyRef} className="card">
            <div className="card-content">
              <p className="typo-strong-600">The quick fox</p>
              <p className="typo-strong-500">The quick fox</p>
              <p className="typo-strong-400">The quick fox</p>
              <p className="typo-strong-300">The quick fox</p>
              <p className="typo-strong-225">The quick fox</p>
              <p className="typo-strong-200">The quick fox</p>
              <p className="typo-strong-175">The quick fox</p>
              <p className="typo-strong-150">The quick fox</p>
              <p className="typo-strong-125">The quick fox</p>

              <p className="typo-medium-600">The quick fox</p>
              <p className="typo-medium-500">The quick fox</p>
              <p className="typo-medium-400">The quick fox</p>
              <p className="typo-medium-300">The quick fox</p>
              <p className="typo-medium-225">The quick fox</p>
              <p className="typo-medium-200">The quick fox</p>
              <p className="typo-medium-175">The quick fox</p>
              <p className="typo-medium-150">The quick fox</p>
              <p className="typo-medium-125">The quick fox</p>

              <p className="typo-normal-600">The quick fox</p>
              <p className="typo-normal-500">The quick fox</p>
              <p className="typo-normal-400">The quick fox</p>
              <p className="typo-normal-300">The quick fox</p>
              <p className="typo-normal-225">The quick fox</p>
              <p className="typo-normal-200">The quick fox</p>
              <p className="typo-normal-175">The quick fox</p>
              <p className="typo-normal-150">The quick fox</p>
              <p className="typo-normal-125">The quick fox</p>

              <p className="typo-hero-large">The quick fox</p>
              <p className="typo-hero-medium">The quick fox</p>

              <p className="typo-header-4xLarge">The quick fox</p>
              <p className="typo-header-3xLarge">The quick fox</p>
              <p className="typo-header-xLarge">The quick fox</p>
              <p className="typo-header-large">The quick fox</p>
              <p className="typo-header-medium">The quick fox</p>
              <p className="typo-header-small">The quick fox</p>

              <p className="typo-body-large">The quick fox</p>
              <p className="typo-body-medium">The quick fox</p>
              <p className="typo-body-small">The quick fox</p>
              <p className="typo-body-xSmall">The quick fox</p>
              <p className="typo-body-strong-large">The quick fox</p>
              <p className="typo-body-strong-medium">The quick fox</p>
              <p className="typo-body-strong-small">The quick fox</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Components;
