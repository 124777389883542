import { useEffect, useState, useContext } from "react";
import { useNavigate, createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, Bell } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";

import Drop from "../components/drop.js";

import { AppContext } from '../context.js';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import Animation from '../files/cubesAnimation.json';

import Events from "../components/events.js";

function Notification(props) {
  const { children } = props;

  const context = useContext(AppContext);

  const [notifications, setNotifications] = useState([]);

  const [closeEventsDrop, setCloseEventsDrop] = useState(0);

  useEffect(() => {
    const es = new EventSource("/api/admin/communication/events/stream?token=" + context.token);

    //es.onerror = (e) => console.log("ERROR!", e);

    // es.onerror = (e) => {
    //   console.error("EventSource failed:", e);
    //   es.close();
    //   setTimeout(() => {
    //     const newEs = new EventSource("/api/admin/communication/notifications/stream?token=" + token);
    //     newEs.onmessage = es.onmessage;
    //     newEs.onerror = es.onerror;
    //   }, 2000);
    // };

    es.onmessage = (e) => {
      setNotifications(JSON.parse(e.data));
    };

    return () => es.close();
   }, []);

  return (
    <Drop close={closeEventsDrop}>
      <div className="item typo-body-medium me-2"><Bell height={16} width={16} background={'none'} fill={'grey'} />Events {notifications.length > 0 && (<Counter color="red" text={notifications.length} />)}</div>
      <div className="card shadowmore" style={{width: 250}}>
        <div className="card-content">
          <Events list={notifications} />
        </div>
      </div>
    </Drop>
  );
}

export default Notification;