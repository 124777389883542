import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, UserPlus, Edit, ChevronDown, ChevronUp, ChevronLeft, ChevronRight, Plus, Minus } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";
import Drop from "./drop.js";

import { AppContext } from '../context.js';

import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";


function RecurrencePicker(props) {
  const { recurrenceAt, recurrenceNumber, recurrenceType, onChange, onChangeID, leftText, leftIcon, rightIcon, className } = props;

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const [next, setNext] = useState(recurrenceAt);
  const [number, setNumber] = useState(recurrenceNumber);
  const [type, setType] = useState(recurrenceType);

  const [closeDrop, setCloseDrop] = useState(0);

  useEffect(() => {
    setNext(recurrenceAt);
    setNumber(recurrenceNumber ? recurrenceNumber : 1);
    setType(recurrenceType ? recurrenceType : 1);
  }, [recurrenceAt]);

  function changeNumber(newNumber) {
    newNumber = parseInt(newNumber);
    if (newNumber > 0) {
      setNumber(newNumber);
    }
  }

  function save() {
    onChange(number, type, onChangeID);
    setCloseDrop(closeDrop + 1);
  }

  function remove() {
    onChange(null, null, onChangeID);
    setCloseDrop(closeDrop + 1);
  }

  if (loading) {
    return <></>;
  } 

  return (
    <Drop close={closeDrop}>
      <div className={"item grey " + className}>
        {leftIcon && leftIcon}
        {leftText && <span className="typo-normal-150">{leftText}</span>}
        <span className="typo-normal-150">{(recurrenceNumber && recurrenceType) ? 'every ' + recurrenceNumber + ' ' + (recurrenceType === 1 ? 'day' : '') + (recurrenceType === 2 ? 'week' : '') + (recurrenceType === 3 ? 'month' : '') + (recurrenceNumber > 1 ? 's' : '') : 'not set'}</span>
        {rightIcon && rightIcon}
      </div>
      <div className="card shadowmore" style={{width: 'fit-content'}}>
        <div className="card-header sm">
          {next && (
            <>
              <div className="typo-normal-150 text-muted">Next recurrence:</div>
              <div className="typo-medium-150 mb-2">{DateToFormat(TimestampToDate(next), context.dateFormat)}</div>
            </>
          )}

          <div className="typo-normal-150 text-muted">Repeat every:</div>
          <div className="d-flex justify-content-between">
            <div onClick={() => changeNumber(number - 1)} className="item me-1">
              <Minus height={16} width={16} background={'none'} fill={'grey'} />
            </div>
        
            <input type="text" className="input borderless text-center typo-normal-150" value={number} onChange={(e) => changeNumber(e.target.value)} style={{width: 30}} />

            <div onClick={() => changeNumber(number + 1)} className="item ms-1">
              <Plus height={16} width={16} background={'none'} fill={'grey'} />
            </div>

            <div className={'item ms-2 ' + (type === 1 ? 'blue' : '')} onClick={() => setType(1)}><span className="typo-normal-150">Days</span></div>
            <div className={'item ms-2 ' + (type === 2 ? 'blue' : '')} onClick={() => setType(2)}><span className="typo-normal-150">Weeks</span></div>
            <div className={'item ms-2 ' + (type === 3 ? 'blue' : '')} onClick={() => setType(3)}><span className="typo-normal-150">Months</span></div>
          </div>

          <div className="d-flex justify-content-start mt-2">
            <div className="item grey" onClick={() => save()}><span className="typo-normal-150">Save</span></div>
            {next && (
              <div className="item grey ms-2" onClick={() => remove()}><span className="typo-normal-150">Remove</span></div>
            )}
          </div>
            
        </div>
      </div>
    </Drop>
  );
}

export default RecurrencePicker;