import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import { Badge, BadgeInformative, BadgeNegative, BadgeNeutral } from '../components/component.js';

import { AlarmClock } from "../components/icons.js";

import { AppContext } from '../context.js';

function Analytic() {
  const context = useContext(AppContext);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <div className="card">
            <div className="card-content">
              <Link to="https://recharts.org/en-US/examples" target="_blank">Examples</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytic;
