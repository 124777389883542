import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, UserPlus, Edit, ChevronDown } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";
import Drop from "./drop.js";

import { AppContext } from '../context.js';


function UserPicker(props) {
  const { userList, userID, onChange, onChangeID, leftText, leftIcon, rightIcon, className } = props;

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);

  const [passedUserID, setPassedUserID] = useState(userID);
  const [selectedUser, setSelectedUser] = useState(users.find((u) => u.id === passedUserID));

  const [closeUserDrop, setCloseUserDrop] = useState(0);

  useEffect(() => {
    if (userList) {
      setUsers(userList);
      setLoading(false);
      return;
    }
    
    let u = context.addSpiner();
    axios.post('/api/admin/iam/users/list', {
      // id: id,
      // userID: userID
    }).then((response) => {
      setUsers(response.data.users);
      setLoading(false);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  useEffect(() => {
    setPassedUserID(userID);
    setSelectedUser(users.find((u) => u.id === userID));
  }, [userID, loading]);

  if (loading) {
    return <></>;
  } 

  return (
    <Drop close={closeUserDrop}>
      <div className={"item grey " + className}>
        {leftIcon && leftIcon}
        {leftText && <span className="typo-normal-150">{leftText}</span>}
        {selectedUser ? (
          <Avatar url={selectedUser.profileImageName} />
        ) : (
          <UserPlus height={16} width={16} background={'none'} fill={'grey'} />
        )}
        <span className="typo-normal-150">{selectedUser ? selectedUser.firstName + " " + selectedUser.lastName + (selectedUser.roleID === 2 && selectedUser.profile.speciality ? ' | ' + selectedUser.profile.speciality : '') + (selectedUser.roleID === 3 && selectedUser.profile.mrn ? ' | ' + selectedUser.profile.mrn : '') : "Unknown"}</span>
        {rightIcon && rightIcon}
      </div>
      <div className="card shadowmore" style={{width: 'fit-content'}}>
        <div className="card-header sm">
          {users.map((user, i) => {
            return (
              <div className="side-item small nowrap" onClick={() => { onChange(user, onChangeID); setCloseUserDrop(closeUserDrop + 1) }}>
                <Avatar url={users.find((u) => u.id === user.id).profileImageName} /><span className="typo-normal-150">{user.firstName + " " + user.lastName + (user.roleID === 2 && user.profile.speciality ? ' | ' + user.profile.speciality : '') + (user.roleID === 3 && user.profile.mrn ? ' | ' + user.profile.mrn : '')}</span>
            </div>
            );
          })}
        </div>
      </div>
    </Drop>
  );
}

export default UserPicker;