import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";

import { AppContext } from '../context.js';
import { Button } from "../components/component.js";

function Profile() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  function logout() {
    context.clearState()
    navigate("/")
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="typo-header-3xLarge">{context.username}</span><br/>
                  <span className="typo-normal-175">Your personal settings</span>
                </div>
                <div>
                  <Button color="red" text="Log Out" onClick={logout} />
                </div>
              </div>
            </div>
            {/* <div className="card-content">
              <div className="row">
                <div className="col-xs-2">
                  <div className="side-item active typo-body-large mb-1">Profile</div>
                  <div className="side-item typo-body-large mb-1">Users</div>
                  <div className="side-item typo-body-large">Projects</div>
                  <div className="side-item typo-body-large">Tasks</div>
                </div>
                <div className="col-xs-offset-1 col-xs-9">
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
