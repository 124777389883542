import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate, Link } from "react-router-dom";
import axios from 'axios';

import { AppContext } from '../context.js';
import { Copy, FilePlus, TimeClock, Search, UnfoldMore, MarkerPin, MoreVertical, ChevronUp, File, Comment, ChevronDown, ChevronDown2, ChevronDown3, ChevronUp2, ChevronUp3, Minus2, Hashtag, Filter } from "../components/icons.js";

import { Avatar, Status, Button, Counter } from '../components/component.js';
import Loading from '../components/loading.js';
import Helmet from "../components/helmet.js";

import UserPreview from "../components/userPreview.js";
import LocationPreview from "../components/locationPreview.js";

function Patients() {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState([]);
  const [filterSelected, setFilterSelected] = useState(0);
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/user/filters/patients/list', {
        // TODO add user id
      }),
      axios.post('/api/admin/iam/users/list', {
        roleID: 3
      }),
    ]).then(axios.spread((filters, users) => {
      setFilters(filters.data.filters);
      setUsers(users.data.users);
      setLoading(false);
    })).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  function renderPatients() {
    return users.map((user) => {
      return (
        <div onClick={() => navigate(`/patient/${user.id}`)} className="card clickable mb-2">
          <div className="card-content">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center typo-strong-300">
                  
                <span className="typo-strong-200">{user.firstName + ' ' + user.lastName}</span>

                {/* <span className="typo-strong-200 ms-4">{project.summary ? project.summary : 'No title'}</span> */}
                  
                {/* <div className="d-flex justify-content-start align-items-center ms-4">
                  <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{project.projectTypeName}</span>
                </div> */}

              </div>
              {/* <div className="d-flex justify-content-end align-items-center w-25">
                123
              </div> */}
            </div>
          </div>
        </div>
      );
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet title="Patients" />

      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="card mb-4">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  {/* <div className="d-flex align-items-center text-muted">
                    <Search height={16} width={16} background={'none'} fill={'grey'} /> <span className="ms-1">Type to search...</span>
                  </div> */}
                  <div className="d-flex align-items-center">
                    <div className="item rect" onClick={() => setFiltersExpanded(!filtersExpanded)}>
                      <Filter height={14} width={16} background={'none'} fill={'grey'} />
                    </div>
                    <div className={"item ms-2 " + (filterSelected === 0 ? "active" : "")} onClick={() => setFilterSelected(0)}>
                      <span className="typo-normal-150">Queue</span>
                    </div>
                    {filters.map((filter) => {
                      return (
                        <div className={"item ms-2 " + (filterSelected === filter.id ? "active" : "")} onClick={() => setFilterSelected(filter.id)}>
                          <span className="typo-normal-150">{filter.title}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <div className="item typo-normal-150 ms-2" onClick={() => navigate('/patient/create')}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                  </div>
                </div>
              </div>
              {filtersExpanded && (
                <div className="card-content">
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    Many types of filters, if expanded, will be shown here.
                    <br/>
                    Priority
                  </div>
                </div>
              )}
            </div>
            {renderPatients()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Patients;
