import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, UserPlus, Edit, ChevronDown, MarkerPin } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";
import Drop from "./drop.js";

import { AppContext } from '../context.js';

function LocationPicker(props) {
  const { locationList, locationID, onChange, onChangeID, leftText, leftIcon, rightIcon } = props;

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [locations, setLocations] = useState([]);

  const [passedLocationID, setPassedLocationID] = useState(locationID);
  const [selectedLocation, setSelectedLocation] = useState(locations.find((l) => l.id === passedLocationID));

  const [closeLocationDrop, setCloseLocationDrop] = useState(0);

  useEffect(() => {
    if (locationList) {
      setLocations(locationList);
      setLoading(false);
      return;
    }
    
    let u = context.addSpiner();
    axios.post('/api/admin/org/locations/list', {
      // id: id,
      // userID: userID
    }).then((response) => {
      setLocations(response.data.locations);
      setLoading(false);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, []);

  useEffect(() => {
    setPassedLocationID(locationID);
    setSelectedLocation(locations.find((l) => l.id === locationID));
  }, [locationID, loading]);

  if (loading) {
    return <></>;
  } 

  return (
    <Drop close={closeLocationDrop}>
      <div className="item grey">
        {leftIcon && leftIcon}
        {leftText && <span className="typo-normal-150">{leftText}</span>}
        <MarkerPin height={16} width={16} background={'none'} fill={'grey'} />
        <span className="typo-normal-150">{selectedLocation ? selectedLocation.name + (selectedLocation.description ? " | " + selectedLocation.description : "") : "Unknown"}</span>
        {rightIcon && rightIcon}
      </div>
      <div className="card shadowmore" style={{width: 'fit-content'}}>
        <div className="card-header sm">
          {locations.map((location, i) => {
            return (
              <div className="side-item small nowrap" onClick={() => { onChange(location, onChangeID); setCloseLocationDrop(closeLocationDrop + 1) }}>
                {/* <Avatar url={users.find((u) => u.id === user.id).profileImageName} /> */}
                <span className="typo-normal-150">{location.name}{location.description ? " | " + location.description : ""}</span>
            </div>
            );
          })}
        </div>
      </div>
    </Drop>
  );
}

export default LocationPicker;