import { useEffect, useState, useContext, useRef } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import axios from 'axios';

import Logo from "../files/logo.svg";

import { AlarmClock, Alert, Activity, Folder, Users, Speedmeter, Inbox, Settings, CodeAlt, FilePlus, UserPlus, Edit, ChevronDown, MarkerPin, Calendar, ChevronLeft, ChevronRight } from "../components/icons.js";

import { Avatar, Counter } from "../components/component.js";
import Drop from "./drop.js";

import { AppContext } from '../context.js';

import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";

function DatePicker(props) {
  const { date, onChange, onChangeID, leftText, leftIcon, rightIcon, className,  } = props;

  const context = useContext(AppContext);

 // const [passedDate, setPassedDate] = useState(new Date());

  const [closeDateDrop, setCloseDateDrop] = useState(0);
  
  function dateSelected(newDate) {
    //setDate(newDate);
    if (onChange) { 
      if (onChangeID) {
        onChange(newDate, onChangeID);
      } else {
        onChange(newDate);
      }
    }
    setCloseDateDrop(closeDateDrop + 1);
  }

  return (
    <Drop close={closeDateDrop}>
      <div className={"item grey " + className}>
        {leftIcon && leftIcon}
        {leftText && <span className="typo-normal-150">{leftText}</span>}
        <span className="typo-normal-150">{date ? DateToFormat(date, context.dateFormat) : "not set"}</span>
        {rightIcon && rightIcon}
      </div>
      <div className="card shadowmore" style={{width: 'fit-content'}}>
        <div className="card-header sm">
          <DateSelector date={date} dateSelected={dateSelected} />
        </div>
      </div>
    </Drop>
  );
}

function SlotPicker(props) {
  const { userList, userID, onChange, onChangeID, leftText, rightIcon, className } = props;

  const context = useContext(AppContext);

  const [date, setDate] = useState(new Date());

  const [slots, setSlots] = useState([
    {start: '08:00', end: '09:00'},
    {start: '09:00', end: '10:00'},
    {start: '10:00', end: '11:00'},
    {start: '11:00', end: '12:00'},
    {start: '12:00', end: '13:00'},
    {start: '13:00', end: '14:00'},
    {start: '14:00', end: '15:00'},
    {start: '15:00', end: '16:00'},
  ]);

  const [closeDateDrop, setCloseDateDrop] = useState(0);
  
  function dateSelected(newDate) {
    setDate(newDate);
  }

  return (
    <Drop close={closeDateDrop}>
      <div className={"item grey " + className}>
        {leftText && <span className="typo-normal-150">{leftText}</span>}
        <span className="typo-normal-150"> {"25.10.2024 | 09:00 - 10:00" }</span>
        {rightIcon && rightIcon}
      </div>
      <div className="card shadowmore" style={{width: 'fit-content'}}>
        <div className="card-header sm">
          <div className="d-flex justify-content-between align-items-top">
            <DateSelector date={date} dateSelected={dateSelected} />
            
            <div className="ms-3 text-center" style={{width: 120, borderLeft: '1px solid lightgrey'}}>
              <div className="item typo-normal-125 mb-2">{date.getDate()} {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}</div>
              {slots.map((slot) => {
                return (
                  <div key={"slot_"+slot.start} className="item">
                    <span className="typo-normal-150">{slot.start} - {slot.end}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Drop>
  );
}

function DateSelector(props) {
  const { date, dateSelected } = props;

  const context = useContext(AppContext);

  const [selectedDay, setSelectedDay] = useState(date ? date : null);
  const [visibleDay, setVisibleDay] = useState(date ? date : new Date());
  const [currentDay, setCurrentDay] = useState(new Date());

  const [grid, setGrid] = useState([]);

  useEffect(() => {
    setSelectedDay(date);
  }, [date]);

  useEffect(() => {
    const year = visibleDay.getFullYear();
    const month = visibleDay.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    //console.log(`Number of days in month: ${daysInMonth}`);
    const firstDay = new Date(year, month, 1).getDay();
    //console.log(`First day of month: ${firstDay}`);
    const lastDay = new Date(year, month, daysInMonth).getDay();
    //console.log(`Last day of month: ${lastDay}`);

    const grid = [
      ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    ];

    let row = [];
    for (let i = 0; i < firstDay; i++) {
      row.push('');
    }
    for (let i = 1; i <= daysInMonth; i++) {
      row.push(i);
      if (row.length === 7) {
        grid.push(row);
        row = [];
      }
    }
    if (row.length > 0) {
      while (row.length < 7) {
        row.push('');
      }
      grid.push(row);
    }

    setGrid(grid);
  }, [visibleDay]);

  function prevMonth() {
    const newDate = new Date(visibleDay.getFullYear(), visibleDay.getMonth() - 1, 1);
    setVisibleDay(newDate);
  }

  function nextMonth() {
    const newDate = new Date(visibleDay.getFullYear(), visibleDay.getMonth() + 1, 1);
    setVisibleDay(newDate);
  }

  function selectDay(i, j) {
    let d = new Date(visibleDay.getFullYear(), visibleDay.getMonth(), grid[i][j]);
    setSelectedDay(d);
    dateSelected(d);
  }

  return (
    <div style={{width: 210}}>
      <div className="d-flex justify-content-between align-items-center">
        <div onClick={prevMonth} className="item">
          <ChevronLeft height={16} width={16} background={'none'} fill={'grey'} />
        </div>
        <span className="typo-normal-125">{visibleDay.toLocaleString('default', { month: 'long' })} {visibleDay.getFullYear()}</span>
        <div onClick={nextMonth} className="item">
          <ChevronRight height={16} width={16} background={'none'} fill={'grey'} />
        </div>
      </div>
      <div className="datepicker-days">
        {grid.map((row, i) => {
          return (
            <div className={"datepicker-row d-flex justify-content-between align-items-center " + (i === 0 ? "header" : "")}>
              {row.map((day, j) => {
                let className = "datepicker-day typo-normal-150";
                if (i === 0) {
                  className += " header ";
                }
                if (i !== 0 && day !== '') {
                  className += " clickable ";
                }
                if (day === currentDay.getDate() && visibleDay.getMonth() === currentDay.getMonth() && visibleDay.getFullYear() === currentDay.getFullYear()) {
                  className += " today ";
                }
                if (selectedDay && day === selectedDay.getDate() && visibleDay.getMonth() === selectedDay.getMonth() && visibleDay.getFullYear() === selectedDay.getFullYear()) {
                  className += " active ";
                }
                return (
                  <div key={i + '-' + j} className={className} onClick={() => selectDay(i, j)}>
                    {day}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { DatePicker, SlotPicker };