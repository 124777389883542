import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import { Helmet as H } from "react-helmet";

import { AppContext } from '../context.js';

function Helmet(props) {
  const { title } = props;

  const context = useContext(AppContext);

  return (
    <H>
      <title>{title}</title>
    </H>
  );
}

export default Helmet;
