import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, useNavigate, Link, useParams } from "react-router-dom";
import axios from 'axios';

import { Avatar, Status, Button } from "../components/component.js";
import { Copy, FilePlus, TimeClock, MarkerPin, MoreVertical, ChevronUp, File, Comment, ChevronDown, ChevronDown2, ChevronUp2, ChevronUp3, Minus2, Hashtag, ArrowRight, Close, UserPlus, User, UserEdit, CalendarEdit, Bank, Refresh, Crown, Home, Eye, ArrowLeft, Bookmark } from "../components/icons.js";
import Editor from "../components/editor.js";
import Drop from "../components/drop.js";
import Chat from "../components/chat.js";
import Events from "../components/events.js";

import { AppContext } from '../context.js';

import TaskCreateModal from "../desktop/task-create-modal.js";
import AppointmentCreateModal from "../desktop/appointment-create-modal.js";

import { TimestampToDate, DateToTimestamp, DateToFormat } from "../utils/datetime.js";

import UserPicker from "../components/userPicker.js";
import UserPreview from "../components/userPreview.js";
import LocationPicker from "../components/locationPicker.js";
import LocationPreview from "../components/locationPreview.js";
import { DatePicker, SlotPicker } from "../components/datePicker.js";
import RecurrencePicker from "../components/recurrencePicker.js";
import ResourcePicker from "../components/resourcePicker.js";

import BillingPicker from "../components/billingPicker.js";

function Project() {
  const context = useContext(AppContext);

  let { id } = useParams();

  let projectID = Number(id);

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [org, setOrg] = useState(null);

  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);

  const [summaryTabSelected, setSummaryTabSelected] = useState("summary");
  const [chatTabSelected, setChatTabSelected] = useState("public");
  const [summaryHeightLimit, setSummaryHeightLimit] = useState(true);

  const [expandedTasks, setExpandedTasks] = useState([]);
  const [expandedAppointments, setExpandedAppointments] = useState([]);

  const [createTaskModalVisible, setCreateTaskModalVisible] = useState(false);
  const [createAppointmentModalVisible, setCreateAppointmentModalVisible] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const [refreshHistory, setRefreshHistory] = useState(0);

  const [closeProjectPriorityDrop, setCloseProjectPriorityDrop] = useState(0);
  const [closeProjectStatusDrop, setCloseProjectStatusDrop] = useState(0);
  const [closeWatchListDrop, setCloseWatchListDrop] = useState(0);
  const [closeTaskStatusDrop, setCloseTaskStatusDrop] = useState(0);
  const [closeAppointmentStatusDrop, setCloseAppointmentStatusDrop] = useState(0);

  const [taskFilter, setTaskFilter] = useState("all"); // all, internal, patient
  const [taskLimitStep, setTaskLimitStep] = useState(4);
  const [taskLimit, setTaskLimit] = useState(taskLimitStep);
  const [taskShowCompleted, setTaskShowCompleted] = useState(false);

  const [appointmentLimitStep, setAppointmentLimitStep] = useState(4);
  const [appointmentLimit, setAppointmentLimit] = useState(appointmentLimitStep);
  const [appointmentShowCompleted, setAppointmentShowCompleted] = useState(false);

  useEffect(() => {
    let u = context.addSpiner();
    axios.all([
      axios.post('/api/admin/care/projects/get', {
        id: projectID
      }),
      axios.post('/api/admin/iam/users/list', {
        // TODO
      }),
      axios.post('/api/admin/org/locations/list', {
        // TODO
      }),
      axios.post('/api/admin/org/settings/get', {
        // TODO
      }),
    ]).then(axios.spread((project, users, locations, org) => {
      setProject(project.data);
      setUsers(users.data.users);
      setLocations(locations.data.locations);
      setOrg(org.data);

      // let expandedTasks = [];
      // project.data.tasks.forEach((task) => {
      //   if (task.chatID) {
      //     expandedTasks.push(task.id);
      //   }
      // });

      setLoading(false);
    })).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }, [id]);

  // Project
  function changeProjectPriority(priority) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updatePriority', {
      id: project.id,
      priority: priority
    }).then((response) => {
      setProject({ ...project, priority: priority });
      setCloseProjectPriorityDrop(closeProjectPriorityDrop + 1);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeProjectStatus(status) {
    let newStatus = 3;
    if (status.isInitial) {
      newStatus = 1;
    }
    if (status.isPaused) {
      newStatus = 2;
    }
    if (status.isCompleted) {
      newStatus = 4;
    }
    if (status.isCancelled) {
      newStatus = 5;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateStatus', {
      id: projectID,
      statusID: status.id,
    }).then((response) => {
      setProject({ ...project, status: newStatus, statusID: status.id, statusName: status.title });
      setCloseProjectStatusDrop(closeProjectStatusDrop + 1);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeTaskStatus(id, status) {
    let newStatus = 3;
    if (status.isInitial) {
      newStatus = 1;
    }
    if (status.isPaused) {
      newStatus = 2;
    }
    if (status.isCompleted) {
      newStatus = 4;
    }
    if (status.isCancelled) {
      newStatus = 5;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateStatus', {
      id: id,
      statusID: status.id,
    }).then((response) => {
      const updatedTasks = project.tasks.map((task) => {
        if (task.id === id) {
          return { ...task, status: newStatus, statusID: status.id, statusName: status.title };
        }
        return task;
      });
      setProject({ ...project, tasks: updatedTasks });
      setCloseTaskStatusDrop(closeTaskStatusDrop + 1);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeAppointmentStatus(id, status) {
    let newStatus = 3;
    if (status.isInitial) {
      newStatus = 1;
    }
    if (status.isPaused) {
      newStatus = 2;
    }
    if (status.isCompleted) {
      newStatus = 4;
    }
    if (status.isCancelled) {
      newStatus = 5;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/appointments/updateStatus', {
      id: id,
      statusID: status.id,
    }).then((response) => {
      const updatedAppointments = project.appointments.map((appointment) => {
        if (appointment.id === id) {
          return { ...appointment, status: newStatus, statusID: status.id, statusName: status.title };
        }
        return appointment;
      });
      setProject({ ...project, appointments: updatedAppointments });
      setCloseAppointmentStatusDrop(closeAppointmentStatusDrop + 1);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeSummary(text) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateSummary', {
      id: projectID,
      summary: text,
    }).then((response) => {
      setProject({ ...project, summary: text });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeSummaryPublic(text) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateSummaryPublic', {
      id: projectID,
      summaryPublic: text,
    }).then((response) => {
      setProject({ ...project, summaryPublic: text });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateProjectUser(user, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateUser', {
      id: id,
      userID: user.id
    }).then((response) => {
      setProject({ ...project, userID: user.id, userName: user.firstName + " " + user.lastName });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateProjectPatient(patient, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updatePatient', {
      id: id,
      patientID: patient.id
    }).then((response) => {
      setProject({ ...project, patientID: patient.id, patientName: patient.firstName + " " + patient.lastName });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateProjectLocation(location, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateLocation', {
      id: id,
      locationID: location.id
    }).then((response) => {
      setProject({ ...project, locationID: location.id, locationName: location.name });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateProjectWatchList() {
    let watching = false;
    if (project.watchList && project.watchList.find((id) => id === context.userID)) {
      watching = true;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/projects/updateWatchList', {
      id: projectID
    }).then((response) => {
      if (watching) {
        setProject({ ...project, watchList: project.watchList.filter((id) => id !== context.userID) });
      } else {
        setProject({ ...project, watchList: [...project.watchList, context.userID] });
      }
      setCloseWatchListDrop(closeWatchListDrop + 1);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // Tasks
  function changeTaskTitle(id, text) {
    const updatedTasks = project.tasks.map((task) => {
      if (task.id === id) {
        return { ...task, title: text };
      }
      return task;
    });
    setProject({ ...project, tasks: updatedTasks });
  }

  function keyUpTaskTitle(e) {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  function updateTaskTitle(id) {
    const task = project.tasks.find((task) => task.id === id);
    if (task) {
      let u = context.addSpiner();
      axios.post('/api/admin/care/tasks/updateTitle', {
        id: task.id,
        text: task.title
      }).then((response) => {
        
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        context.removeSpiner(u);
      });
    }
  }

  function changeTaskDescription(id, text) {
    const updatedTasks = project.tasks.map((task) => {
      if (task.id === id) {
        return { ...task, description: text };
      }
      return task;
    });
    setProject({ ...project, tasks: updatedTasks });
  }

  function updateTaskDescription(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateDescription', {
      id: id,
      text: text
    }).then((response) => {
      const updatedTasks = project.tasks.map((task) => {
        if (task.id === id) {
          return { ...task, description: text };
        }
        return task;
      });
      setProject({ ...project, tasks: updatedTasks });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeTaskInstruction(id, text) {
    const updatedTasks = project.tasks.map((task) => {
      if (task.id === id) {
        return { ...task, instruction: text };
      }
      return task;
    });
    setProject({ ...project, tasks: updatedTasks });
  }

  function updateTaskInstruction(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateInstruction', {
      id: id,
      text: text
    }).then((response) => {
      const updatedTasks = project.tasks.map((task) => {
        if (task.id === id) {
          return { ...task, instruction: text };
        }
        return task;
      });
      setProject({ ...project, tasks: updatedTasks });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskUser(user, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateUser', {
      id: id,
      userID: user.id
    }).then((response) => {
      const updatedTasks = project.tasks.map((task) => {
        if (task.id === id) {
          return { ...task, userID: user.id, userName: user.firstName + " " + user.lastName };
        }
        return task;
      });
      setProject({ ...project, tasks: updatedTasks });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskDeadline(date, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateDeadline', {
      id: id,
      deadline: DateToTimestamp(date)
    }).then((response) => {
      const updatedTasks = project.tasks.map((task) => {
        if (task.id === id) {
          return { ...task, deadline: DateToTimestamp(date) };
        }
        return task;
      });
      setProject({ ...project, tasks: updatedTasks });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function expandTask(id) {
    if (expandedTasks.includes(id)) {
      setExpandedTasks(expandedTasks.filter((item) => item !== id));
    } else {
      setExpandedTasks([...expandedTasks, id]);
    }
  }

  function changeTaskPatientVisibility(id, visible) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updatePatientVisibility', {
      id: id,
      visible: visible
    }).then((response) => {
      const updatedTasks = project.tasks.map((task) => {
        if (task.id === id) {
          return { ...task, patientTask: visible };
        }
        return task;
      });
      setProject({ ...project, tasks: updatedTasks });
      setCloseTaskStatusDrop(closeTaskStatusDrop + 1);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskBillingCode(code, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateBillingCode', {
      id: id,
      billingCodeID: code.id
    }).then((response) => {
      const updatedTasks = project.tasks.map((task) => {
        if (task.id === id) {
          return { ...task, billingCodeID: code.id };
        }
        return task;
      });
      setProject({ ...project, tasks: updatedTasks });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateTaskRecurrence(number, type, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/tasks/updateRecurrence', {
      id: id,
      recurrenceNumber: number,
      recurrenceType: type,
    }).then((response) => {
      const updatedTasks = project.tasks.map((task) => {
        if (task.id === id) {
          return { ...task, 
            recurrenceAt: response.data.recurrenceAt,
            recurrenceNumber: response.data.recurrenceNumber,
            recurrenceType: response.data.recurrenceType,
          };
        }
        return task;
      });
      setProject({ ...project, tasks: updatedTasks });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  // Appointments
  function changeAppointmentTitle(id, text) {
    const updatedAppointments = project.appointments.map((appointment) => {
      if (appointment.id === id) {
        return { ...appointment, title: text };
      }
      return appointment;
    });
    setProject({ ...project, appointments: updatedAppointments });
  }

  function keyUpAppointmentTitle(e) {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  function updateAppointmentTitle(id) {
    const appointment = project.appointments.find((appointment) => appointment.id === id);
    if (appointment) {
      let u = context.addSpiner();
      axios.post('/api/admin/care/appointments/updateTitle', {
        id: appointment.id,
        text: appointment.title
      }).then((response) => {
        
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        context.removeSpiner(u);
      });
    }
  }

  function changeAppointmentDescription(id, text) {
    const updatedAppointments = project.appointments.map((appointment) => {
      if (appointment.id === id) {
        return { ...appointment, description: text };
      }
      return appointment;
    });
    setProject({ ...project, appointments: updatedAppointments });
  }

  function updateAppointmentDescription(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/appointments/updateDescription', {
      id: id,
      text: text
    }).then((response) => {
      const updatedAppointments = project.appointments.map((appointment) => {
        if (appointment.id === id) {
          return { ...appointment, description: text };
        }
        return appointment;
      });
      setProject({ ...project, appointments: updatedAppointments });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function changeAppointmentInstruction(id, text) {
    const updatedAppointments = project.appointments.map((appointment) => {
      if (appointment.id === id) {
        return { ...appointment, instruction: text };
      }
      return appointment;
    });
    setProject({ ...project, appointments: updatedAppointments });
  }

  function updateAppointmentInstruction(text, id) {
    if (text === "" || text === "<p><br></p>") {
      text = null;
    }

    let u = context.addSpiner();
    axios.post('/api/admin/care/appointments/updateInstruction', {
      id: id,
      text: text
    }).then((response) => {
      const updatedAppointments = project.appointments.map((appointment) => {
        if (appointment.id === id) {
          return { ...appointment, instruction: text };
        }
        return appointment;
      });
      setProject({ ...project, appointments: updatedAppointments });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function updateAppointmentUser(user, id) {
    let u = context.addSpiner();
    axios.post('/api/admin/care/appointments/updateUser', {
      id: id,
      userID: user.id
    }).then((response) => {
      const updatedAppointments = project.appointments.map((appointment) => {
        if (appointment.id === id) {
          return { ...appointment, userID: user.id, userName: user.firstName + " " + user.lastName };
        }
        return appointment;
      });
      setProject({ ...project, appointments: updatedAppointments });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      context.removeSpiner(u);
    });
  }

  function expandAppointment(id) {
    if (expandedAppointments.includes(id)) {
      setExpandedAppointments(expandedAppointments.filter((item) => item !== id));
    } else {
      setExpandedAppointments([...expandedAppointments, id]);
    }
  }

  // Callbacks
  function createMessageCallback() {
    setRefreshHistory(refreshHistory + 1);
  }

  function createTaskCallback(task) {
    setProject({ ...project, tasks: [...project.tasks, task] });
  }

  function createAppointmentCallback(appointment) {
    setProject({ ...project, appointments: [...project.appointments, appointment] });
  }

  // Render helpers
  function renderTasks() {
    let list = project.tasks.filter((t) => taskFilter === "all" || (taskFilter === "internal" && !t.patientTask) || (taskFilter === "patient" && t.patientTask))
    if (!taskShowCompleted) {
      list = list.filter((t) => t.status !== 4 && t.status !== 5);
    }
      
    return list.slice(0, taskLimit).map((task, ti) => {
      return (
        <div key={"task_" + task.id} className="mb-2">
          <div className="card">
            <div className="card-header sm">
              <div className="d-flex justify-content-between align-items-center">
              <div className="typo-strong-200 me-2">{task.sequenceNumber}.</div>
                <input type="text" className="input borderless typo-strong-200 w-100" placeholder="Title..." value={task.title} onChange={(e) => changeTaskTitle(task.id, e.target.value)} onBlur={() => updateTaskTitle(task.id)} onKeyUp={(e) => keyUpTaskTitle(e)} />
                <div className="d-flex justify-content-end align-items-center w-25">
                  {renderTaskStatus(task.statusID)}
                  <Drop close={closeTaskStatusDrop} className="ms-1">
                    <div className="item rect">
                      <MoreVertical height={14} width={16} background={'none'} fill={'grey'} />
                    </div>
                    <div className="card" style={{width: 200}}>
                      <div className="card-header sm">
                        <div className="typo-normal-150 text-muted">Change status:</div>
                        {renderTaskNextStatuses(task.id, task.statusID)}
                        <div className="typo-normal-150 text-muted">Change status back to:</div>
                        {renderTaskPrevStatuses(task.id, task.statusID)}
                        <div className="typo-normal-150 text-muted">Patient interaction:</div>
                        {task.patientTask ? (
                          <div className="side-item small typo-medium-150" onClick={() => changeTaskPatientVisibility(task.id, false)}>
                            Hide task from patient
                          </div>
                        ) : (
                          <div className="side-item small typo-medium-150" onClick={() => changeTaskPatientVisibility(task.id, true)}>
                            Show task to patient
                          </div>
                        )}
                      </div>
                    </div>
                  </Drop>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-6">
                  <span className="typo-strong-150">Description:</span>
                  <Editor 
                    value={task.description}
                    onChangeID={task.id}
                    onBlur={updateTaskDescription}
                    toolbar="limited"
                    className="text-editor borderless h-100"
                  />
                </div>
                <div className="col-xs-6">
                  <span className="typo-strong-150">Patient instruction:</span>
                  <Editor 
                    value={task.instruction}
                    onChangeID={task.id}
                    onBlur={updateTaskInstruction}
                    toolbar="limited"
                    className="text-editor borderless h-100"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-start align-items-center mt-2">
                <div className="d-flex justify-content-start align-items-center">
                  <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{task.taskTypeName}</span>
                </div>

                {task.patientTask && (
                  <div className="d-flex justify-content-start align-items-center ms-3 text-brand">
                    <Hashtag height={16} width={16} background={'none'} fill={'#113997'} /> <span className="typo-normal-150">Patient task</span>
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="d-flex justify-content-start align-items-center w-100">
                  <UserPicker
                    userList={users.filter((u) => u.roleID === 2)}
                    userID={task.userID}
                    onChange={updateTaskUser}
                    onChangeID={task.id}
                    leftIcon={<UserEdit height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  />

                  <DatePicker 
                    date={task.deadline ? TimestampToDate(task.deadline) : null}
                    onChange={updateTaskDeadline}
                    onChangeID={task.id}
                    leftIcon={<CalendarEdit height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className={"me-2 " + (task.deadline && DateToTimestamp(new Date()) < task.deadline && DateToTimestamp(new Date()) + 86400 > task.deadline ? "yellow" : "") +
                      (task.deadline && DateToTimestamp(new Date()) > task.deadline  ? "red" : "")
                    }
                  />

                  <BillingPicker 
                    codeList={project.projectCodes}
                    codeID={task.billingCodeID}
                    onChange={updateTaskBillingCode}
                    onChangeID={task.id}
                    leftIcon={<Bank height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  />

                  <RecurrencePicker 
                    recurrenceAt={task.recurrenceAt}
                    recurrenceNumber={task.recurrenceNumber}
                    recurrenceType={task.recurrenceType}
                    onChange={updateTaskRecurrence}
                    onChangeID={task.id}
                    leftIcon={<Refresh height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  />

                  <ResourcePicker 
                    task={task}
                    leftIcon={<Bookmark height={16} width={16} background={'none'} fill={'grey'} />}
                    rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                    className="me-2"
                  />
                </div>
                <div className="d-flex justify-content-end align-items-bottom">
                  <div onClick={() => expandTask(task.id)}>
                    {expandedTasks.includes(task.id) ? (
                      <div className="item grey">
                        <Comment height={16} width={16} background={'none'} fill={'grey'} />
                        <span className="typo-normal-150">chat</span>
                        <ChevronUp height={14} width={16} background={'none'} fill={'grey'} />
                      </div>
                    ) : (
                      <div className="item grey">
                        <Comment height={16} width={16} background={'none'} fill={'grey'} />
                        <span className="typo-normal-150">chat</span>
                        <ChevronDown height={14} width={16} background={'none'} fill={'grey'} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {expandedTasks.includes(task.id) && (
              <div className="card-content sm">
                <Chat
                  chatID={task.chatID}
                  createMessageCallback={createMessageCallback}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  function renderTasksLimit() {
    let tasks = project.tasks.filter((t) => taskFilter === "all" || (taskFilter === "internal" && !t.patientTask) || (taskFilter === "patient" && t.patientTask));
    
    if (!taskShowCompleted) {
      tasks = tasks.filter((t) => t.status !== 4 && t.status !== 5);
    }

    if (tasks.length <= taskLimitStep) {
      return <></>;
    }

    let more = taskLimit + taskLimitStep;
    if (more > tasks.length) {
      more = tasks.length;
    }

    let less = taskLimit - taskLimitStep;
    if (less < taskLimitStep) {
      less = taskLimitStep;
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        {tasks.length > taskLimit && (
          <div className="item typo-normal-150 me-2" onClick={() => setTaskLimit(more)}>Show more</div>
        )}
        {taskLimit > taskLimitStep && (
          <div className="item typo-normal-150 me-2" onClick={() => setTaskLimit(less)}>Show less</div>
        )}
        {taskLimit !== tasks.length && (
          <div className="item typo-normal-150 me-2" onClick={() => setTaskLimit(tasks.length)}>Show all</div>
        )}
        {taskLimit !== taskLimitStep && (
          <div className="item typo-normal-150 me-2" onClick={() => setTaskLimit(taskLimitStep)}>Show minimum</div>
        )}
      </div>
    );
  }

  function renderAppointments() {
    let list = project.appointments;
    if (!appointmentShowCompleted) {
      list = list.filter((t) => t.status !== 4 && t.status !== 5);
    }

    return list.slice(0, appointmentLimit).map((appointment, ai) => {
       return (
        <div key={"appointment_" + ai} className="mb-2">
          <div className="card">
            <div className="card-header sm">
              <div className="d-flex justify-content-between align-items-top">

                <div className="d-flex justify-content-between align-items-top" style={{width: 120}}>
                  <div className="pe-3" style={{width: 40}}>
                    <div className="typo-strong-300 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), 'DD')}</div>
                    <div className="typo-strong-150 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), 'M3').toUpperCase()}</div>
                  </div>
                  <div style={{width: 80}}>
                    <div className="typo-strong-175 text-center text-brand">{DateToFormat(TimestampToDate(appointment.startAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
                    <div className="typo-normal-125 text-center text-brand">{appointment.duration} min</div>
                    <div className="typo-strong-175 text-center text-brand">{DateToFormat(TimestampToDate(appointment.endAt), context.timeFormat === "12" ? "AMPM" : "HH:mm")}</div>
                  </div>
                </div>

                <div className="w-100 ms-3 ps-3" style={{borderLeft: '1px solid lightgrey'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <input type="text" className="input borderless typo-strong-200 w-100" placeholder="Title..." value={appointment.title} onChange={(e) => changeAppointmentTitle(appointment.id, e.target.value)} onBlur={() => updateAppointmentTitle(appointment.id)} onKeyUp={(e) => keyUpAppointmentTitle(e)} />
                    <div className="d-flex justify-content-end align-items-center w-25">
                      {renderAppointmentStatus(appointment.statusID)}
                      <Drop close={closeAppointmentStatusDrop} className="ms-1">
                        <div className="item rect">
                          <MoreVertical height={14} width={16} background={'none'} fill={'grey'} />
                        </div>
                        <div className="card" style={{width: 200}}>
                          <div className="card-header sm">
                            <div className="typo-normal-150 text-muted">Change status:</div>
                            {renderAppointmentNextStatuses(appointment.id, appointment.statusID)}
                            <div className="typo-normal-150 text-muted">Change status back to:</div>
                            {renderAppointmentPrevStatuses(appointment.id, appointment.statusID)}
                          </div>
                        </div>
                      </Drop>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-6">
                      <span className="typo-strong-150">Description:</span>
                      <Editor 
                        value={appointment.description}
                        onChangeID={appointment.id}
                        onBlur={updateAppointmentDescription}
                        toolbar="limited"
                        className="text-editor borderless h-100"
                      />
                    </div>
                    <div className="col-xs-6">
                      <span className="typo-strong-150">Patient instruction:</span>
                      <Editor 
                        value={appointment.instruction}
                        onChangeID={appointment.id}
                        onBlur={updateAppointmentInstruction}
                        toolbar="limited"
                        className="text-editor borderless h-100"
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-start align-items-center mt-2">
                    <div className="d-flex justify-content-start align-items-center">
                      <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{appointment.appointmentTypeName}</span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="d-flex justify-content-start align-items-center w-75">
                      <UserPicker
                        userList={users.filter((u) => u.roleID === 2)}
                        userID={appointment.userID}
                        onChange={updateAppointmentUser}
                        onChangeID={appointment.id}
                        leftIcon={<UserEdit height={16} width={16} background={'none'} fill={'grey'} />}
                        rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                        className="me-2"
                      />
                    </div>
                    <div className="d-flex justify-content-end align-items-bottom w-25">
                      <div onClick={() => expandAppointment(appointment.id)}>
                        {expandedAppointments.includes(appointment.id) ? (
                          <div className="item rect">
                            <span className="typo-normal-150">chat</span><ChevronUp height={14} width={16} background={'none'} fill={'grey'} />
                          </div>
                        ) : (
                          <div className="item rect">
                            <span className="typo-normal-150">chat</span><ChevronDown height={14} width={16} background={'none'} fill={'grey'} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {expandedAppointments.includes(appointment.id) && (
              <div className="card-content sm">
                <Chat
                  chatID={appointment.chatID}
                  createMessageCallback={createMessageCallback}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  function renderAppointmentsLimit() {
    let appointments = project.appointments;
    
    if (!appointmentShowCompleted) {
      appointments = appointments.filter((t) => t.status !== 4 && t.status !== 5);
    }

    if (appointments.length <= appointmentLimitStep) {
      return <></>;
    }

    let more = appointmentLimit + appointmentLimitStep;
    if (more > appointments.length) {
      more = appointments.length;
    }

    let less = appointmentLimit - appointmentLimitStep;
    if (less < appointmentLimitStep) {
      less = appointmentLimitStep;
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        {appointments.length > appointmentLimit && (
          <div className="item typo-normal-150 me-2" onClick={() => setAppointmentLimit(more)}>Show more</div>
        )}
        {appointmentLimit > appointmentLimitStep && (
          <div className="item typo-normal-150 me-2" onClick={() => setAppointmentLimit(less)}>Show less</div>
        )}
        {appointmentLimit !== appointments.length && (
          <div className="item typo-normal-150 me-2" onClick={() => setAppointmentLimit(appointments.length)}>Show all</div>
        )}
        {appointmentLimit !== appointmentLimitStep && (
          <div className="item typo-normal-150 me-2" onClick={() => setAppointmentLimit(appointmentLimitStep)}>Show minimum</div>
        )}
      </div>
    );
  }

  function renderProjectStatus(id) {
    const status = project.projectStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} text={status.title} />
    );
  }

  function renderProjectNextStatuses() {
    const currentStatus = project.projectStatuses.find((ps) => ps.id === project.statusID);

    if (!currentStatus.next) {
      return (
        <div className="typo-medium-150">
          Project has no next statuses
        </div>
      );
    }

    return project.projectStatuses.filter((status) => currentStatus.next.includes(status.id)).map((status) => {
      return (
        <div className="side-item small nowrap typo-medium-150" onClick={() => changeProjectStatus(status)}>
          To <ArrowRight height={14} width={16} background={'none'} fill={'grey'} /> {renderProjectStatus(status.id)}
        </div>
      );
    })
  }

  function renderProjectPrevStatuses() {
    const currentStatus = project.projectStatuses.find((ps) => ps.id === project.statusID);

    if (!currentStatus.prev) {
      return (
        <div className="typo-medium-150">
          Project has no previous statuses
        </div>
      );
    }

    return project.projectStatuses.filter((status) => currentStatus.prev.includes(status.id)).map((status) => {
      return (
        <div className="side-item small nowrap typo-medium-150" onClick={() => changeProjectStatus(status)}>
          To <ArrowLeft height={14} width={16} background={'none'} fill={'grey'} /> {renderProjectStatus(status.id)}
        </div>
      );
    })
  }

  function renderTaskStatus(id) {
    const status = project.taskStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  function renderTaskPrevStatuses(taskID, statusID) {
    const currentStatus = project.taskStatuses.find((ts) => ts.id === statusID);

    if (!currentStatus.prev) {
      return (
        <div className="typo-medium-150">
          Task has no previous statuses
        </div>
      );
    }

    return project.taskStatuses.filter((status) => currentStatus.prev.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeTaskStatus(taskID, status)}>
          To <ArrowLeft height={14} width={16} background={'none'} fill={'grey'} /> {renderTaskStatus(status.id)}
        </div>
      );
    })
  }

  function renderTaskNextStatuses(taskID, statusID) {
    const currentStatus = project.taskStatuses.find((ts) => ts.id === statusID);

    if (!currentStatus.next) {
      return (
        <div className="typo-medium-150">
          Task has no next statuses
        </div>
      );
    }

    return project.taskStatuses.filter((status) => currentStatus.next.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeTaskStatus(taskID, status)}>
          To <ArrowRight height={14} width={16} background={'none'} fill={'grey'} /> {renderTaskStatus(status.id)}
        </div>
      );
    })
  }

  function renderAppointmentStatus(id) {
    const status = project.appointmentStatuses.find((ps) => ps.id === id);

    var statusColor = "blue";

    if (status.isInitial || status.isPaused) {
      statusColor = "grey";
    } 

    if (status.isCompleted) {
      statusColor = "green";
    }

    if (status.isCancelled) {
      statusColor = "red";
    }

    return (
      <Status color={statusColor} muted={true} size={'small'} text={status.title} />
    );
  }

  function renderAppointmentPrevStatuses(appointmentID, statusID) {
    const currentStatus = project.appointmentStatuses.find((as) => as.id === statusID);

    if (!currentStatus.prev) {
      return (
        <div className="typo-medium-150">
          Appointment has no previous statuses
        </div>
      );
    }

    return project.appointmentStatuses.filter((status) => currentStatus.prev.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeAppointmentStatus(appointmentID, status)}>
          To <ArrowLeft height={14} width={16} background={'none'} fill={'grey'} /> {renderAppointmentStatus(status.id)}
        </div>
      );
    })
  }

  function renderAppointmentNextStatuses(appointmentID, statusID) {
    const currentStatus = project.appointmentStatuses.find((as) => as.id === statusID);

    if (!currentStatus.next) {
      return (
        <div className="typo-medium-150">
          Appointment has no next statuses
        </div>
      );
    }

    return project.appointmentStatuses.filter((status) => currentStatus.next.includes(status.id)).map((status) => {
      return (
        <div className="side-item small typo-medium-150" onClick={() => changeAppointmentStatus(appointmentID, status)}>
          To <ArrowRight height={14} width={16} background={'none'} fill={'grey'} /> {renderAppointmentStatus(status.id)}
        </div>
      );
    })
  }

  function renderProjectPriority() {
    switch (project.priority) {
      case -2:
        return <ChevronDown2 height={16} width={16} background={'none'} fill={'blue'} />;
      case -1:
        return <ChevronDown height={16} width={16} background={'none'} fill={'blue'} />;
      case 0:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
      case 1:
        return <ChevronUp height={16} width={16} background={'none'} fill={'red'} />;
      case 2:
        return <ChevronUp2 height={16} width={16} background={'none'} fill={'red'} />;
      default:
        return <Minus2 height={16} width={16} background={'none'} fill={'orange'} />;
    }
  }

  function renderProjectID() {
    return project.id.toString().padStart(project.projectType.projectMask, '0');
  }

  if (loading) {
    return (
      <></>
    );
  }

  return (
    <>
      {createTaskModalVisible && (
        <TaskCreateModal
          projectID={project.id}
          projectTypeID={project.projectTypeID}
          setVisible={setCreateTaskModalVisible}
          createTaskCallback={createTaskCallback}
        />
      )}

      {createAppointmentModalVisible && (
        <AppointmentCreateModal
          projectID={project.id}
          projectTypeID={project.projectTypeID}
          setVisible={setCreateAppointmentModalVisible}
          createAppointmentCallback={createAppointmentCallback}
        />
      )}

      <div className="container-fluid">
        <div className="row pb-4">
          <div className={showHistory ? "col-xs-offset-1 col-xs-9" : "col-xs-offset-1 col-xs-10"}>
            <div className="row mb-3">
              <div className="col-xs-12">
                <div className="card">
                  <div className="card-content sm">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="d-flex justify-content-start align-items-center typo-strong-400">
                        <img src={"/files/"+org.logo+"?token=token1"} alt="logo" style={{height: 40}} />
                        
                        <span className="ms-4 me-1">{project.projectType.projectAlias}-{renderProjectID()}</span>
                        
                        <div className="item">
                          <Copy height={16} width={16} background={'none'} fill={'grey'} />
                        </div>

                        <div className="d-flex justify-content-start align-items-center ms-4">
                          <Hashtag height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">{project.projectTypeName}</span>
                        </div>
                        
                      </div>
                      <div className="d-flex justify-content-end align-items-center w-25">
                        {renderProjectStatus(project.statusID)}
                        <Drop close={closeProjectStatusDrop} className="ms-1">
                          <div className="item rect">
                            <MoreVertical height={14} width={16} background={'none'} fill={'grey'} />
                          </div>
                          <div className="card" style={{width: 200}}>
                            <div className="card-header sm">
                              <div className="typo-normal-150 text-muted">Change status:</div>
                              {renderProjectNextStatuses()}
                              <div className="typo-normal-150 text-muted">Change status back:</div>
                              {renderProjectPrevStatuses()}
                            </div>
                          </div>
                        </Drop>
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="d-flex justify-content-start align-items-center me-3">
                        <Drop close={closeProjectPriorityDrop}>
                          <div className={"item grey " + (project.priority < 0 ? "blue" : "") + (project.priority > 0 ? "red" : "")}>
                            {renderProjectPriority()}
                            <span className="typo-normal-150">{project.projectType.projectPriorityLabels[project.priority+2]}</span>
                            <ChevronDown height={16} width={16} background={'none'} fill={'grey'} />
                          </div>
                          <div className="card shadowmore" style={{width: 208}}>
                            <div className="card-header sm">
                              <div className="item me-2" onClick={() => changeProjectPriority(-2)}>
                                <ChevronDown2 height={16} width={16} background={'none'} fill={'blue'} />
                              </div>
                              <div className="item me-2" onClick={() => changeProjectPriority(-1)}>
                                <ChevronDown height={16} width={16} background={'none'} fill={'blue'} />
                              </div>
                              <div className="item me-2" onClick={() => changeProjectPriority(0)}>
                                <Minus2 height={16} width={16} background={'none'} fill={'orange'} />
                              </div>
                              <div className="item me-2" onClick={() => changeProjectPriority(1)}>
                                <ChevronUp height={16} width={16} background={'none'} fill={'red'} />
                              </div>
                              <div className="item" onClick={() => changeProjectPriority(2)}>
                                <ChevronUp2 height={16} width={16} background={'none'} fill={'red'} />
                              </div>
                            </div>
                          </div>
                        </Drop>
                      </div>

                      <div className="d-flex justify-content-start align-items-center me-3">
                        <UserPicker
                          userList={users.filter((user) => user.roleID === 2)}
                          userID={project.userID}
                          onChange={updateProjectUser}
                          onChangeID={project.id}
                          leftIcon={<UserEdit height={16} width={16} background={'none'} fill={'grey'} />}
                          rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                        />
                      </div>

                      <div className="d-flex justify-content-start align-items-center me-3">
                        <Drop close={closeWatchListDrop}>
                          <div className={"item grey"}>
                            <Eye height={16} width={16} background={'none'} fill={'grey'} />
                            <span className="typo-normal-150">{project.watchList ? project.watchList.length : 0}</span>
                            <ChevronDown height={16} width={16} background={'none'} fill={'grey'} />
                          </div>
                          <div className="card shadowmore" style={{minWidth: 200, width: 'fit-content'}}>
                            <div className="card-header sm">
                              <div className="typo-normal-150 text-muted">Actions:</div>
                              <div className="side-item small nowrap" onClick={() => updateProjectWatchList()}>
                                <span className="typo-normal-150">{(project.watchList && project.watchList.find((id) => id === context.userID)) ? 'Stop watching' : 'Start watching'}</span>
                              </div>
                              <div className="typo-normal-150 text-muted">Watching this project:</div>
                              {(project.watchList && project.watchList.length > 0) ? (
                                users.filter((u) => project.watchList.includes(u.id)).map((user, i) => {
                                  return (
                                    <div className="side-item small nowrap">
                                      <Avatar url={users.find((u) => u.id === user.id).profileImageName} /><span className="typo-normal-150">{user.firstName + " " + user.lastName + (user.roleID === 2 && user.profile.speciality ? ' | ' + user.profile.speciality : '')}</span>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="typo-medium-150">
                                  No users watching this project
                                </div>
                              )}
                            </div>
                          </div>
                        </Drop>
                      </div>

                      <div className="d-flex justify-content-start align-items-center me-3">
                        {project.patientID ? (
                          <UserPreview
                            userList={users.filter((user) => user.roleID === 3)}
                            userID={project.patientID}
                            leftIcon={<Crown height={16} width={16} background={'none'} fill={'grey'} />}
                            rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                          />
                        ) : (
                          <UserPicker
                            userList={users.filter((user) => user.roleID === 3)}
                            userID={project.patientID}
                            onChange={updateProjectPatient}
                            onChangeID={project.id}
                            leftIcon={<Crown height={16} width={16} background={'none'} fill={'grey'} />}
                            rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                          />
                        )}
                      </div>

                      <div className="d-flex justify-content-start align-items-center me-3">
                        {project.locationID ? (
                          <LocationPreview
                            locationList={locations}
                            locationID={project.locationID}
                            leftIcon={<Home height={16} width={16} background={'none'} fill={'grey'} />}
                            rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                          />
                        ) : (
                          <LocationPicker
                            locationList={locations}
                            locationID={project.locationID}
                            onChange={updateProjectLocation}
                            onChangeID={project.id}
                            leftIcon={<Home height={16} width={16} background={'none'} fill={'grey'} />}
                            rightIcon={<ChevronDown height={16} width={16} background={'none'} fill={'grey'} />}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-4">
                <div className="card mb-2">
                  <div className="card-header sm d-flex justify-content-between align-items-center">
                    <span className="typo-strong-200">{summaryTabSelected === "summary" ? "Care Plan Overview" : "Care Plan Review"}</span>
                    <div>
                      <div className={"item typo-normal-150 "  + (summaryTabSelected === "summary" ? "active" : "")} onClick={() => setSummaryTabSelected("summary")}>Internal</div>
                      <div className={"item typo-normal-150 ms-2 " + (summaryTabSelected === "public" ? "active" : "")} onClick={() => setSummaryTabSelected("public")}>Public</div>
                    </div>
                  </div>

                  <div className={summaryHeightLimit ? "scrollable" : ""} style={summaryHeightLimit ? {height: "400px", overflowX: 'auto'} : {}}>
                    {summaryTabSelected === "summary" ? (
                      <div className="card-content">

                        <Editor 
                          placeholder="Description..."
                          value={project.summary}
                          onBlur={changeSummary}
                          toolbar="full"
                          className="text-editor borderless"
                        />
                      </div>
                    ) : (
                      <div className="card-content">
                        <Editor 
                          placeholder="Description..."
                          value={project.summaryPublic}
                          onBlur={changeSummaryPublic}
                          toolbar="full"
                          className="text-editor borderless"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center mb-3">
                  {summaryHeightLimit ? (
                    <div className="item typo-normal-150 ms-2" onClick={() => setSummaryHeightLimit(false)}>Show full text</div>
                  ) : (
                    <div className="item typo-normal-150 ms-2" onClick={() => setSummaryHeightLimit(true)}>Hide full text</div>
                  )}
                </div>

                <div className="card mb-3">
                  <div className="card-header sm d-flex justify-content-between align-items-center">
                    <span className="typo-strong-200">Chat</span>
                    <div>
                      <div className={"item typo-normal-150 "  + (chatTabSelected === "chat" ? "active" : "")} onClick={() => setChatTabSelected("chat")}>Internal</div>
                      <div className={"item typo-normal-150 ms-2 " + (chatTabSelected === "public" ? "active" : "")} onClick={() => setChatTabSelected("public")}>Public</div>
                    </div>
                  </div>
                  {chatTabSelected === "chat" ? (
                    <div className="card-content">
                      <Chat
                        chatID={project.chatID}
                        createMessageCallback={createMessageCallback}
                      />
                    </div>
                  ) : (
                    <div className="card-content">
                      <Chat
                        chatID={project.patientChatID}
                        createMessageCallback={createMessageCallback}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xs-8">
                <div className="ps-4">
                  <div className="card shadowmore mb-2">
                    <div className="card-content sm d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="typo-strong-200">Tasks</span>

                        <div className={"item typo-normal-150 ms-4 "  + (taskFilter === "all" ? "active" : "")} onClick={() => {setTaskFilter("all"); setTaskLimit(3)}}>All</div>
                        <div className={"item typo-normal-150 ms-2 " + (taskFilter === "internal" ? "active" : "")} onClick={() => {setTaskFilter("internal"); setTaskLimit(3)}}>Internal</div>
                        <div className={"item typo-normal-150 ms-2 " + (taskFilter === "patient" ? "active" : "")} onClick={() => {setTaskFilter("patient"); setTaskLimit(3)}}>Patient</div>

                        <div className={"item typo-normal-150 ms-4 " + (taskShowCompleted ? "active" : "")} onClick={() => {setTaskShowCompleted(!taskShowCompleted)}}>Show completed</div>
                      </div>
                      <div className="item typo-normal-150 ms-2" onClick={setCreateTaskModalVisible}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                    </div>
                  </div>
                  {renderTasks()}
                  {renderTasksLimit()}

                  <div className="card shadowmore mt-4 mb-2">
                    <div className="card-content sm d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="typo-strong-200">Appointments</span>

                        {/* <div className={"item typo-normal-150 ms-4 "  + (taskFilter === "all" ? "active" : "")} onClick={() => {setTaskFilter("all"); setTaskLimit(3)}}>All</div>
                        <div className={"item typo-normal-150 ms-2 " + (taskFilter === "internal" ? "active" : "")} onClick={() => {setTaskFilter("internal"); setTaskLimit(3)}}>Internal</div>
                        <div className={"item typo-normal-150 ms-2 " + (taskFilter === "patient" ? "active" : "")} onClick={() => {setTaskFilter("patient"); setTaskLimit(3)}}>Patient</div> */}

                        <div className={"item typo-normal-150 ms-4 " + (appointmentShowCompleted ? "active" : "")} onClick={() => {setAppointmentShowCompleted(!appointmentShowCompleted)}}>Show completed</div>
                      </div>
                      <div className="item typo-normal-150 ms-2" onClick={setCreateAppointmentModalVisible}><FilePlus height={16} width={16} background={'none'} fill={'grey'} /> Create</div>
                    </div>
                  </div>
                  {renderAppointments()}
                  {renderAppointmentsLimit()}
                </div>
              </div>
            </div>
          </div>
          <div className={showHistory ? "col-xs-2" : "col-xs-1"}>
            {showHistory ? (
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <span className="typo-strong-175">History</span>
                  <div className="item" onClick={() => setShowHistory(false)}>
                    <Close height={16} width={16} background={'none'} fill={'grey'} />
                  </div>
                </div>
                <div className="card-content">
                  <Events projectID={project.id} taskID={null} appointmentID={null} refresh={refreshHistory} />
                </div>
              </div>
            ) : (
              <div className="" style={{paddingTop: 12}}>
                <div className="item" onClick={() => setShowHistory(true)}>
                  <TimeClock height={16} width={16} background={'none'} fill={'grey'} /> <span className="typo-normal-150">History</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Project;
